.avitor-profile {
    display: flex;
    align-items: center;
    &-img {
        min-width: 30px;
        max-width: 30px;
        margin-right: 10px;
        img {
            border-radius: 50%;
        }
    }
    &-cnt {
        .uiname {
            font-size: 13px;
            color: #000000;
            line-height: 148%;
            margin-bottom: 0px;
        }
        .uirole {
            font-size: 9px;
            color: #000000;
            line-height: 148%;
            margin-bottom: 0px;
        }
    }
}

.bg-admin-area-default-color {
    background-color: #F2F3F7;
    margin-top: 138px;
}

.dash-board-content-area {
    padding: 40px 0 80px;
}

.customizing-area {
    border-radius: 24px;
    padding: 30px;
    background-color: #fff;
    box-shadow: -0px 0px 10px rgba(0, 0, 0, 0.05);
}

.couples-list {
    position: relative;
    #mytable_wrapper>.dt-layout-row .dt-layout-cell.dt-layout-end {
        margin-left: 0!important;
    }
    .dt-search {
        width: 300px;
        label {
            display: none;
        }
        input {
            width: 100%;
            border: 1px solid #CCCCCC!important;
            height: 30px;
            border-radius: 4px!important;
            font-size: 12px!important;
            color: #B9B9B9!important;
            font-weight: 300;
            padding: 0 15px !important;
        }
    }
    thead {
        background-color: #F1F2F3;
        .dt-column-title {
            font-size: 14px;
            color: #999999;
            font-weight: 400;
        }
        th {
            border: none!important;
            background-color: #F1F2F3;
            font-size: 14px;
            color: #999999;
            font-weight: 400;
        }
    }
    tbody {
        tr {
            border-bottom: 1px solid #EFEFEF;
        }
        tr>:nth-child(1) {
            font-size: 16px;
            color: #F28482;
            font-weight: 500;
            padding: 17px 12px;
            a {
                color: #F28482;
            }
        }
        th {
            color: #000000;
            font-size: 16px;
            font-weight: 400;
        }
        .statelogin {
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: #009379;
            border-radius: 50%;
            margin-right: 12px;
            &.inactive {
                background-color: #999999;
            }
            &.draft {
                background-color: #F29B30;
            }
        }
    }
    .paging_simple_numbers {
        position: absolute;
        top: 0;
        right: 0;
        button {
            display: none!important;
        }
        [data-dt-idx="next"],
        [data-dt-idx="previous"] {
            display: inline-block!important;
            background: inherit!important;
            background-repeat: no-repeat!important;
            width: 13px;
            height: 13px;
            font-size: 0;
        }
        [data-dt-idx="previous"] {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.3525 5.75C12.3525 6.13281 12.0518 6.43359 11.6963 6.43359H2.39941L6.03613 9.87891C6.30957 10.125 6.30957 10.5625 6.06348 10.8086C5.81738 11.082 5.40723 11.082 5.13379 10.8359L0.321289 6.24219C0.18457 6.10547 0.129883 5.94141 0.129883 5.75C0.129883 5.58594 0.18457 5.42188 0.321289 5.28516L5.13379 0.691406C5.40723 0.445312 5.81738 0.445312 6.06348 0.71875C6.30957 0.964844 6.30957 1.40234 6.03613 1.64844L2.39941 5.09375H11.6963C12.0791 5.09375 12.3525 5.39453 12.3525 5.75Z' fill='%23F28482'/%3E%3C/svg%3E%0A")!important;
            margin-right: 10px;
        }
        [data-dt-idx="next"] {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.21875 0.691406L12.0312 5.28516C12.168 5.42188 12.25 5.58594 12.25 5.77734C12.25 5.94141 12.168 6.10547 12.0312 6.24219L7.21875 10.8359C6.97266 11.082 6.53516 11.082 6.28906 10.8086C6.04297 10.5625 6.04297 10.125 6.31641 9.87891L9.95312 6.43359H0.65625C0.273438 6.43359 0 6.13281 0 5.77734C0 5.39453 0.273438 5.12109 0.65625 5.12109H9.95312L6.31641 1.64844C6.04297 1.40234 6.04297 0.964844 6.28906 0.71875C6.53516 0.445312 6.94531 0.445312 7.21875 0.691406Z' fill='%23F28482'/%3E%3C/svg%3E%0A")!important;
        }
    }
    span.dt-column-order:after {
        padding-top: 2px!important;
    }
}



.wedding-planners-list {
   position: relative;
   #mytable_wrapper>.dt-layout-row .dt-layout-cell.dt-layout-end {
       margin-left: 0!important;
   }
   .dt-search {
       width: 300px;
       label {
           display: none;
       }
       input {
           width: 100%;
           border: 1px solid #CCCCCC!important;
           height: 30px;
           border-radius: 4px!important;
           font-size: 12px!important;
           color: #B9B9B9!important;
           font-weight: 300;
           padding: 0 15px !important;
       }
   }
   thead {
       background-color: #F1F2F3;
       .dt-column-title {
           font-size: 14px;
           color: #999999;
           font-weight: 400;
       }
       th {
           border: none!important;
           background-color: #F1F2F3;
           font-size: 14px;
           color: #999999;
           font-weight: 400;
       }
   }
   tbody {
       tr {
           border-bottom: 1px solid #EFEFEF;
       }
       tr>:nth-child(1) {
           font-size: 16px;
           color: #F28482;
           font-weight: 500;
           padding: 17px 12px;
           a {
               color: #F28482;
               text-decoration: none;
           }
       }
       th {
           color: #000000;
           font-size: 16px;
           font-weight: 400;
       }
       .statelogin {
           display: inline-block;
           width: 10px;
           height: 10px;
           background-color: #009379;
           border-radius: 50%;
           margin-right: 12px;
           &.inactive {
               background-color: #999999;
           }
           &.draft {
               background-color: #F29B30;
           }
       }
   }
   .paging_simple_numbers {
       position: absolute;
       top: 0;
       right: 0;
       button {
           display: none!important;
       }
       [data-dt-idx="next"],
       [data-dt-idx="previous"] {
           display: inline-block!important;
           background: inherit!important;
           background-repeat: no-repeat!important;
           width: 13px;
           height: 13px;
           font-size: 0;
       }
       [data-dt-idx="previous"] {
           background-image: url("data:image/svg+xml,%0A%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.3525 5.75C12.3525 6.13281 12.0518 6.43359 11.6963 6.43359H2.39941L6.03613 9.87891C6.30957 10.125 6.30957 10.5625 6.06348 10.8086C5.81738 11.082 5.40723 11.082 5.13379 10.8359L0.321289 6.24219C0.18457 6.10547 0.129883 5.94141 0.129883 5.75C0.129883 5.58594 0.18457 5.42188 0.321289 5.28516L5.13379 0.691406C5.40723 0.445312 5.81738 0.445312 6.06348 0.71875C6.30957 0.964844 6.30957 1.40234 6.03613 1.64844L2.39941 5.09375H11.6963C12.0791 5.09375 12.3525 5.39453 12.3525 5.75Z' fill='%23F28482'/%3E%3C/svg%3E%0A")!important;
           margin-right: 10px;
       }
       [data-dt-idx="next"] {
           background-image: url("data:image/svg+xml,%0A%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.21875 0.691406L12.0312 5.28516C12.168 5.42188 12.25 5.58594 12.25 5.77734C12.25 5.94141 12.168 6.10547 12.0312 6.24219L7.21875 10.8359C6.97266 11.082 6.53516 11.082 6.28906 10.8086C6.04297 10.5625 6.04297 10.125 6.31641 9.87891L9.95312 6.43359H0.65625C0.273438 6.43359 0 6.13281 0 5.77734C0 5.39453 0.273438 5.12109 0.65625 5.12109H9.95312L6.31641 1.64844C6.04297 1.40234 6.04297 0.964844 6.28906 0.71875C6.53516 0.445312 6.94531 0.445312 7.21875 0.691406Z' fill='%23F28482'/%3E%3C/svg%3E%0A")!important;
       }
   }
   span.dt-column-order:after {
       padding-top: 2px!important;
   }
}



.vendor-list {
   position: relative;
   #mytable_wrapper>.dt-layout-row .dt-layout-cell.dt-layout-end {
       margin-left: 0!important;
   }
   .dt-search {
       width: 300px;
       label {
           display: none;
       }
       input {
           width: 100%;
           border: 1px solid #CCCCCC!important;
           height: 30px;
           border-radius: 4px!important;
           font-size: 12px!important;
           color: #B9B9B9!important;
           font-weight: 300;
           padding: 0 15px !important;
       }
   }
   thead {
       background-color: #F1F2F3;
       .dt-column-title {
           font-size: 14px;
           color: #999999;
           font-weight: 400;
       }
       th {
           border: none!important;
           background-color: #F1F2F3;
           font-size: 14px;
           color: #999999;
           font-weight: 400;
       }
   }
   tbody {
       tr {
           border-bottom: 1px solid #EFEFEF;
       }
       tr>:nth-child(1) {
           font-size: 16px;
           color: #F28482;
           font-weight: 500;
           padding: 17px 12px;
           a {
               color: #F28482;
               text-decoration: none;
           }
       }
       th {
           color: #000000;
           font-size: 16px;
           font-weight: 400;
       }
       .statelogin {
           display: inline-block;
           width: 10px;
           height: 10px;
           background-color: #009379;
           border-radius: 50%;
           margin-right: 12px;
           &.inactive {
               background-color: #999999;
           }
           &.draft {
               background-color: #F29B30;
           }
       }
   }
   .paging_simple_numbers {
       position: absolute;
       top: 0;
       right: 0;
       button {
           display: none!important;
       }
       [data-dt-idx="next"],
       [data-dt-idx="previous"] {
           display: inline-block!important;
           background: inherit!important;
           background-repeat: no-repeat!important;
           width: 13px;
           height: 13px;
           font-size: 0;
       }
       [data-dt-idx="previous"] {
           background-image: url("data:image/svg+xml,%0A%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.3525 5.75C12.3525 6.13281 12.0518 6.43359 11.6963 6.43359H2.39941L6.03613 9.87891C6.30957 10.125 6.30957 10.5625 6.06348 10.8086C5.81738 11.082 5.40723 11.082 5.13379 10.8359L0.321289 6.24219C0.18457 6.10547 0.129883 5.94141 0.129883 5.75C0.129883 5.58594 0.18457 5.42188 0.321289 5.28516L5.13379 0.691406C5.40723 0.445312 5.81738 0.445312 6.06348 0.71875C6.30957 0.964844 6.30957 1.40234 6.03613 1.64844L2.39941 5.09375H11.6963C12.0791 5.09375 12.3525 5.39453 12.3525 5.75Z' fill='%23F28482'/%3E%3C/svg%3E%0A")!important;
           margin-right: 10px;
       }
       [data-dt-idx="next"] {
           background-image: url("data:image/svg+xml,%0A%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.21875 0.691406L12.0312 5.28516C12.168 5.42188 12.25 5.58594 12.25 5.77734C12.25 5.94141 12.168 6.10547 12.0312 6.24219L7.21875 10.8359C6.97266 11.082 6.53516 11.082 6.28906 10.8086C6.04297 10.5625 6.04297 10.125 6.31641 9.87891L9.95312 6.43359H0.65625C0.273438 6.43359 0 6.13281 0 5.77734C0 5.39453 0.273438 5.12109 0.65625 5.12109H9.95312L6.31641 1.64844C6.04297 1.40234 6.04297 0.964844 6.28906 0.71875C6.53516 0.445312 6.94531 0.445312 7.21875 0.691406Z' fill='%23F28482'/%3E%3C/svg%3E%0A")!important;
       }
   }
   span.dt-column-order:after {
       padding-top: 2px!important;
   }
}

@include up-xl {
    .ff_1 {
        width: 25%!important;
    }
    .ff_2 {
        width: 37%!important;
    }
    .ff_3 {
        width: 37%!important;
    }
}

.back-tag {
    display: inline-block;
    font-weight: 500;
    color: #3F4254;
    margin-bottom: 24px;
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 30px;
    text-decoration: none;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9688 7C13.9688 7.4375 13.625 7.78125 13.2188 7.78125H2.59375L6.75 11.7188C7.0625 12 7.0625 12.5 6.78125 12.7812C6.5 13.0938 6.03125 13.0938 5.71875 12.8125L0.21875 7.5625C0.0625 7.40625 0 7.21875 0 7C0 6.8125 0.0625 6.625 0.21875 6.46875L5.71875 1.21875C6.03125 0.9375 6.5 0.9375 6.78125 1.25C7.0625 1.53125 7.0625 2.03125 6.75 2.3125L2.59375 6.25H13.2188C13.6562 6.25 13.9688 6.59375 13.9688 7Z' fill='%23F2828E'/%3E%3C/svg%3E%0A");
}

.act-top-head {
    font-size: 18px;
    color: #3F4254;
    font-weight: 500;
    margin-bottom: 24px;
}

.couples-profile-box {
    position: relative;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    padding: 50px 28px 50px;
    border-radius: 12px;
    .couples-profile-box-img {
        .d-flex {
            justify-content: center;
        }
        .im1 {
            padding-left: 0px;
        }
        .im2 {
            position: relative;
            left: -28px;
        }
    }
    .names_s {
        padding-top: 25px;
        h5 {
            color: #3F4254;
            font-size: 16px;
            margin-bottom: 0;
            font-weight: 600;
        }
        p {
            font-size: 12px;
            color: #80808F;
        }
    }
    .e_mail {
        padding-top: 25px;
        h6 {
            font-size: 14px;
            color: #80808F;
            margin-bottom: 9px;
            font-weight: 400;
        }
        a {
            display: inline-block;
            color: #009379;
            text-decoration: underline;
            margin-bottom: 10px;
        }
    }
    .phone_num {
        padding-top: 25px;
        .phone_num_flex {
            display: flex;
            justify-content: center;
            gap: 5px;
        }
        h6 {
            font-size: 14px;
            color: #80808F;
            margin-bottom: 9px;
            font-weight: 400;
        }
        p {
            font-size: 15px;
            font-weight: 500;
            color: #000000;
            margin-bottom: 5px;
        }
        a {
            display: inline-block;
            color: #009379;
            text-decoration: underline;
            margin-bottom: 0px;
        }
    }
}

.avitor-profile_activity {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    .avitor-profile-cnt {
        .dd {
            color: #B5B5C3;
            font-size: 12px;
            margin-bottom: 0;
        }
    }
}

.activiti_comment_activity {
    .avitor-profile {
        align-items: start;
    }
    .desc {
        color: #3F4254;
        font-size: 11px;
    }
    .days {
        color: #B5B5C3;
        font-size: 11px;
    }
}

.actvity_loop {
    position: relative;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    padding: 20px 28px 30px;
    border-radius: 12px;
    margin-bottom: 25px;
    .activiti_content {
        p {
            color: #3F4254;
            line-height: 20px;
            font-size: 14px;
        }
    }
}

.p_hoto {
    img {
        border-radius: 15px;
    }
}

.op_tion {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
    width: 15px;
    height: 6px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='15' height='6' viewBox='0 0 15 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='9.3996' y='0.359451' width='4.8' height='4.8' rx='2.4' fill='%23B5B5C3'/%3E%3Crect x='0.759949' y='0.359451' width='4.8' height='4.8' rx='2.4' fill='%23B5B5C3' fill-opacity='0.7'/%3E%3C/svg%3E%0A");
}

.wedding_planner {
    position: relative;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    padding: 20px 20px;
    border-radius: 12px;
    @include down-md {
        padding: 15px;
    }
}

.your_vendors {
    position: relative;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    padding: 20px 20px;
    border-radius: 12px;
    @include down-md {
        padding: 15px;
    }
}

.se-profile {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='31' height='32' viewBox='0 0 31 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.208008' y='0.76001' width='30.72' height='30.72' rx='5.76' fill='%23F3F6F9'/%3E%3Crect opacity='0.3' x='9.26746' y='16.9084' width='1.57538' height='7.08923' rx='0.787692' transform='rotate(-90 9.26746 16.9084)' fill='%231BC5BD'/%3E%3Cpath d='M15.0121 20.2902C14.7044 20.5979 14.7044 21.0966 15.0121 21.4042C15.3197 21.7118 15.8184 21.7118 16.126 21.4042L20.8522 16.6781C21.1504 16.3799 21.1608 15.8997 20.8758 15.5888L16.5435 10.8627C16.2496 10.542 15.7513 10.5203 15.4306 10.8143C15.1099 11.1082 15.0883 11.6065 15.3822 11.9272L19.2049 16.0974L15.0121 20.2902Z' fill='%231BC5BD'/%3E%3C/svg%3E%0A");
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    color: #1BC5BD;
    background-repeat: no-repeat;
    background-position: right;
    padding: 5px 40px 5px 0;
    @include down-md {
        font-size: 13px;
        font-weight: 500;
    }
}

.lsting-head {
    font-size: 18px;
    color: #3F4254;
    margin-bottom: 20px;
}

.planner-view {
    position: relative;
    z-index: 1;
    &::before {
        content: '';
        position: absolute;
        left: 16px;
        width: 1px;
        height: 100%;
        background-color: #F5CAC3;
        top: 0px;
        z-index: -1;
    }
    .planner-view-set {
        padding-left: 50px;
        @include down-md {
            padding-left: 25px;
        }
        .ls-profile {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                left: -33px;
                width: 33px;
                height: 1px;
                background-color: #F5CAC3;
                top: 15px;
                @include down-md {
                    left: -10px;
                    width: 10px;
                }
            }
        }
    }
}

.my-tabs {
    button {
        background-color: #E9ECEF;
        color: #3F3F3F;
        font-weight: 500;
        border-radius: 15px 15px 0 0;
        padding: 14px 20px 10px 20px;
        &.active {
            background-color: #fff;
            color: #F28482;
        }
    }
}

.my-tab-cnt {
    background-color: #fff;
    padding: 30px;
    border-radius: 0 0 30px 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}

.stlevel span {
    display: inline-block;
    width: 25px;
    height: 25px;
    background-color: #E2E2E2;
    border-radius: 50%;
}

.event-add-detail {
    h5 {
        color: #000000;
        font-weight: 500;
        margin-bottom: 32px;
    }
    label {
        display: block;
        color: #8E8E8E;
        margin-bottom: 5px;
    }
    .desc-area {
        width: 100%;
        min-height: 120px;
        border: 1px solid #BCBCBC;
        border-radius: 8px;
    }
    .smit {
        border-radius: 5px;
        background-color: #F28482;
        color: #fff;
        width: 100%;
        padding: 10px;
        border: none;
    }
}

.hoster {
    color: #232323;
    background-color: #F5CAC3;
    border-radius: 4px;
    padding: 5px 15px 5px 35px;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    &:before {
        content: "\f007";
        position: absolute;
        font-family: "Font Awesome 6 Free";
        top: 8px;
        font-weight: 400;
        left: 12px;
    }
}

.loc {
    color: #232323;
    background-color: #FCDC6B;
    border-radius: 4px;
    padding: 5px 15px 5px 35px;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    &:before {
        content: "\f601";
        position: absolute;
        font-family: "Font Awesome 6 Free";
        top: 8px;
        font-weight: 600;
        left: 12px;
    }
}


.statelogin.online {
   background-color: green!important;
   width: 10px;
   height: 10px;
   border-radius: 50%;
   display: inline-block;
 }
 
 .statelogin.offline {
   background-color: grey!important;
   width: 10px;
   height: 10px;
   border-radius: 50%;
   display: inline-block;
 }

 .dt-search {
   width: 100%;
   max-width: 300px;
   margin: 0px;
   position: relative;
   margin-bottom: 20px;
 }
 
 .dt-search input[type="search"] {
   width: 100%;
   border: 1px solid #CCCCCC !important;
   height: 30px;
   border-radius: 4px !important;
   font-size: 12px !important;
   color: #B9B9B9 !important;
   font-weight: 300;
   padding: 0 15px !important;
 }
 
 .dt-search input[type="search"]:focus {
   border-color: #d47c7c; /* Match the pinkish theme */
   box-shadow: 0 0 10px rgba(212, 124, 124, 0.5);
 }
 
 .dt-search label {
   display: block;
   font-weight: 600;
   color: #666;
   margin-bottom: 5px;
   font-size: 14px;
 }
 

 .table-bordered>:not(caption)>*>* {
   border-width: 1;
}


table.table-bordered thead>tr>th.dt-orderable-asc:hover, table.table-bordered thead>tr>th.dt-orderable-desc:hover, table.table-bordered thead>tr>td.dt-orderable-asc:hover, table.table-bordered thead>tr>td.dt-orderable-desc:hover {
   outline: 2px solid rgba(0, 0, 0, 0.05);
   outline-offset: -2px;
}

table.table-bordered thead>tr {
   border:none;
}

table.table-bordered tbody>tr {
   border:none;
   border-bottom: 1px solid #EFEFEF;
}

table.table-bordered>:not(caption)>*>* {
   border-width: 0px;
}


.events-table {
   table {
       thead {
           th {
               background-color:#F1F2F3;
               font-size: 14px;
               color: #999999;
               font-weight: 400;

               
           }
          
       }

       tbody {
           tr {
               :nth-child(1) {
                   font-size: 16px;
                   color: #F28482;
                   font-weight: 500;
                   text-transform: capitalize;
               }
           td {
               padding-top: 17px;
               padding-bottom:17px;

               a {
                   text-decoration: none;
               }
           }

           }
       }
   }
}

.festivals-table {
    table {
        thead {
            th {
                background-color:#F1F2F3;
                font-size: 14px;
                color: #999999;
                font-weight: 400;
 
                
            }
           
        }
 
        tbody {
            tr {
                :nth-child(1) {
                    font-size: 16px;
                    color: #F28482;
                    font-weight: 500;
                    text-transform: capitalize;
                }
            td {
                padding-top: 17px;
                padding-bottom:17px;
 
                a {
                    text-decoration: none;
                }
            }
 
            }
        }
    }
 }


/* Gallery Card Styling */
.gallery-card {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .gallery-card img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .gallery-card:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .gallery-card .overlay {
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
    opacity: 0;
  }
  
  .gallery-card:hover .overlay {
    opacity: 1;
  }
  
  .gallery-card .overlay span {
    font-size: 16px;
  }
  
  /* Hover Overlay Stylings */
  .hover-overlay {
    display: none;
  }
  
  .gallery-card:hover .hover-overlay {
    display: flex;
  }
  
  @media (max-width: 768px) {
    .gallery-card {
      border-radius: 4px;
    }
  
    .gallery-card img {
      border-radius: 4px;
    }
  }



  .big_news {
    background-color: #FFE6E5;
    padding: 80px 0;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='498' height='398' viewBox='0 0 498 398' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M382.457 40.9705C319.085 -20.2037 215.342 -20.2037 151.969 40.9705L53.916 135.623L16.36 99.3694C-14.1881 69.8809 -64.203 69.8809 -94.7511 99.3694C-125.299 128.858 -125.299 177.138 -94.7511 206.627C-64.203 236.115 -14.1881 236.115 16.36 206.627L54.4553 169.853L153.946 265.893C216.24 326.026 318.187 326.026 380.481 265.893L498.001 152.449L382.517 40.9705H382.457ZM-1.48976 189.454C-22.2744 209.518 -56.2365 209.518 -76.9613 189.454C-97.746 169.39 -97.746 136.606 -76.9613 116.6C-56.1766 96.5362 -22.2145 96.5362 -1.48976 116.6L36.2462 153.027L-1.48976 189.454ZM362.991 248.373C310.28 299.255 224.087 299.255 171.376 248.373L72.0054 152.449L169.699 58.1433C223.308 6.39379 311.059 6.39379 364.668 58.1433L462.362 152.449L362.991 248.373Z' fill='%23F28482' fill-opacity='0.1'/%3E%3Cpath d='M322.081 99.3722C291.892 70.2306 242.416 70.2306 212.228 99.3722L174.492 135.799L13.3657 -19.7384C-85.1069 -114.796 -246.173 -114.796 -344.646 -19.7384C-443.118 75.3188 -443.118 230.799 -344.646 325.856C-246.173 420.913 -85.1069 420.913 13.3657 325.856L175.031 169.798L213.126 206.572C242.836 235.251 291.413 235.251 321.063 206.572L377.067 152.509L322.021 99.3722H322.081ZM-5.08282 307.989C-93.3727 393.217 -237.787 393.217 -326.077 307.989C-414.367 222.761 -414.367 83.3559 -326.077 -1.8718C-237.787 -87.0994 -93.3727 -87.0994 -5.08282 -1.8718L155.444 153.088L-5.08282 308.047V307.989ZM303.572 188.936C283.566 208.249 250.802 208.249 230.736 188.936L192.94 152.452L230.077 116.603C250.443 96.9437 283.806 96.9437 304.171 116.603L341.308 152.452L303.512 188.936H303.572Z' fill='%23F28482' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        left: 0;
        top: 0;
        height: 400px;
        width: 500px;
    }
    &::after {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        right: 0;
        bottom: 0;
        height: 345px;
        width: 519px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='519' height='346' viewBox='0 0 519 346' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M115.543 356.178C178.915 417.352 282.658 417.352 346.031 356.178L444.084 261.525L481.64 297.779C512.188 327.268 562.203 327.268 592.751 297.779C623.299 268.29 623.299 220.01 592.751 190.522C562.203 161.033 512.188 161.033 481.64 190.522L443.545 227.296L344.054 131.255C281.76 71.1219 179.813 71.1219 117.519 131.255L-0.00108488 244.7L115.483 356.178L115.543 356.178ZM499.49 207.694C520.274 187.631 554.237 187.631 574.961 207.694C595.746 227.758 595.746 260.542 574.961 280.548C554.177 300.612 520.215 300.612 499.49 280.548L461.754 244.121L499.49 207.694ZM135.009 148.775C187.72 97.8929 273.913 97.8929 326.624 148.775L425.995 244.7L328.301 339.005C274.692 390.755 186.941 390.755 133.332 339.005L35.6384 244.7L135.009 148.775Z' fill='%23F28482' fill-opacity='0.1'/%3E%3Cpath d='M175.919 297.776C206.108 326.918 255.584 326.918 285.772 297.776L323.508 261.349L484.634 416.887C583.107 511.944 744.173 511.944 842.646 416.887C941.118 321.83 941.118 166.35 842.646 71.2927C744.173 -23.7644 583.107 -23.7644 484.634 71.2927L322.969 227.351L284.874 190.577C255.164 161.898 206.587 161.898 176.937 190.577L120.933 244.639L175.979 297.776L175.919 297.776ZM503.083 89.1593C591.373 3.93167 735.787 3.93169 824.077 89.1594C912.367 174.387 912.367 313.793 824.077 399.02C735.787 484.248 591.373 484.248 503.083 399.02L342.556 244.061L503.083 89.1015L503.083 89.1593ZM194.428 208.212C214.434 188.9 247.198 188.9 267.264 208.212L305.06 244.697L267.923 280.546C247.557 300.205 214.194 300.205 193.829 280.546L156.692 244.697L194.488 208.212L194.428 208.212Z' fill='%23F28482' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
    }
    .big_blog_sec {
        h4 {
            font-size: 32px;
            font-weight: 700;
            margin: 30px 0 5px 0;
        }
        .red_min {
            font-size: 18px;
            color: #242430;
        }
        .eclip {
            font-size: 44px;
            line-height: 0;
            position: relative;
            top: -4px;
        }
    }
}



.top__news {
    h1 {
        font-size: 80px;
        color: #242430;
        margin-bottom: 20px;
        font-family: KoHo, serif;
    }
    p {
        font-size: 24px;
        color: #242430;
        margin-bottom: 75px;
    }
}


.get_start_pg {
    background-color: #F5CAC3;
    padding: 50px;
    .navbar {
        position: fixed;
        right: 90px;
        top: 75px;
    }
    #main-navigation {
        position: absolute;
        right: 40px;
        width: 180px;
        border: 1px solid #BCBCBC;
        top: 37px;
        border-radius: 20px;
        padding: 23px 26px;
        background-color: #fff;
        a {
            font-size: 14px;
            color: #84A59D;
            font-weight: 500;
            border-bottom: 1px solid #E3E3E3;
            margin-bottom: 14px;
            padding-bottom: 10px;
            padding-top: 0;
        }
    }
    .navbar-toggler {
        border-radius: 0;
        border: none;
        height: 20px;
        width: 27px;
        padding: 0;
        transition: 0.5s;
        z-index: 99999;
        i {
            width: 35px;
            height: 3px;
            display: block;
            margin-bottom: 8px;
            background: #000;
            transition: 0.5s;
            transform-origin: 0;
        }
    }
}

.gt_strat_sec {
    border-radius: 20px;
    padding: 60px;
    p {
        font-weight: 500;
        font-size: 18px;
        color: #4E4E4E;
        img {
            position: relative;
            margin-top: -22px;
            padding-left: 10px;
        }
    }
}

.gt_strat_in {
    p {
        font-weight: 400;
        font-size: 20px;
        color: #000000;
        margin-top: 40px;
    }
}


.rr_m {
    @include up-xl {
        margin: 0 -16px;
        >div {
            padding: 0 16px;
        }
    }
    >div {
        margin-bottom: 65px;
    }
}

.rcnt_post {
    padding-top: 70px;
    padding-bottom: 10px;
    background-color: #F5F5F5;
    h2 {
        font-size: 48px;
        color: #000000;
        margin-bottom: 65px;
        font-family: KoHo, serif;
    }
}

.res_box {
    position: relative;
    overflow: hidden;
    height: 100%;
    border: 1px solid rgb(0 0 0 / 10%);
    border-radius: 12px;
    background-color: #fff;
    padding-bottom: 45px;
    &_img {
        height: 300px;
        background-size: cover;
        width: 100%;
        border-radius: 5px 5px 0 0;
    }
    .res_box_cnt {
        padding: 24px;
        font-family: "Inter", serif;
        .titme {
            font-size: 13px;
            color: #111111;
            display: block;
            margin-bottom: 16px;
            font-weight: 500;
        }
        h5 {
            color: #111111;
            line-height: 139%;
            margin-bottom: 8px;
            font-size: 20px;
            font-weight: 500;
        }
        p {
            color: #111111;
            line-height: 150%;
            margin-bottom: 25px;
            opacity: .6;
        }
        .rm_more_box {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 35px;
            a {
                display: inline-block;
                font-size: 14px;
                color: #111111;
                border: 1px solid #111111;
                border-radius: 50px;
                padding: 9px 20px 9px 16px;
                position: relative;
                margin-left: 24px;
                i {
                    padding-left: 2px;
                }
            }
        }
    }
    &:hover {
        border: 1px solid #F28482;
        background-color: #FFE6E5;
        a {
            background-color: #F28482;
            color: #fff!important;
            border: 1px solid #F28482!important;
        }
    }
}

.articel_loop {
    display: flex;
    align-items: center;
    border-top: 1px solid #F28482;
    padding: 24px 0;
    @include down-md {
        display: block;
    }
    &_in {
        min-width: 260px;
        margin-right: 50px;
    }
    &_cnt {
        p {
            font-size: 28px;
            font-weight: 500;
            margin-bottom: 25px;
        }
        .red_min {
            font-size: 20px;
            color: #969696;
        }
        .eclip {
            font-size: 44px;
            line-height: 0;
            position: relative;
            top: -4px;
        }
    }
}

.l_more {
    display: inline-block;
    border: 2px solid #F28482;
    color: #F28482;
    font-size: 24px;
    font-weight: 500;
    padding: 11px 23px;
    border-radius: 50px;
    margin-top: 25px;
}

.all_articles {
    padding: 24px 0 140px;
    h2 {
        font-size: 48px;
        color: #000000;
        margin-bottom: 24px;
        font-family: KoHo, serif;
    }
}

.top__news {
    h1 {
        font-size: 80px;
        color: #242430;
        margin-bottom: 20px;
        font-family: KoHo, serif;
    }
    p {
        font-size: 24px;
        color: #242430;
        margin-bottom: 75px;
    }
}

.big_news {
    background-color: #FFE6E5;
    padding: 80px 0;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='498' height='398' viewBox='0 0 498 398' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M382.457 40.9705C319.085 -20.2037 215.342 -20.2037 151.969 40.9705L53.916 135.623L16.36 99.3694C-14.1881 69.8809 -64.203 69.8809 -94.7511 99.3694C-125.299 128.858 -125.299 177.138 -94.7511 206.627C-64.203 236.115 -14.1881 236.115 16.36 206.627L54.4553 169.853L153.946 265.893C216.24 326.026 318.187 326.026 380.481 265.893L498.001 152.449L382.517 40.9705H382.457ZM-1.48976 189.454C-22.2744 209.518 -56.2365 209.518 -76.9613 189.454C-97.746 169.39 -97.746 136.606 -76.9613 116.6C-56.1766 96.5362 -22.2145 96.5362 -1.48976 116.6L36.2462 153.027L-1.48976 189.454ZM362.991 248.373C310.28 299.255 224.087 299.255 171.376 248.373L72.0054 152.449L169.699 58.1433C223.308 6.39379 311.059 6.39379 364.668 58.1433L462.362 152.449L362.991 248.373Z' fill='%23F28482' fill-opacity='0.1'/%3E%3Cpath d='M322.081 99.3722C291.892 70.2306 242.416 70.2306 212.228 99.3722L174.492 135.799L13.3657 -19.7384C-85.1069 -114.796 -246.173 -114.796 -344.646 -19.7384C-443.118 75.3188 -443.118 230.799 -344.646 325.856C-246.173 420.913 -85.1069 420.913 13.3657 325.856L175.031 169.798L213.126 206.572C242.836 235.251 291.413 235.251 321.063 206.572L377.067 152.509L322.021 99.3722H322.081ZM-5.08282 307.989C-93.3727 393.217 -237.787 393.217 -326.077 307.989C-414.367 222.761 -414.367 83.3559 -326.077 -1.8718C-237.787 -87.0994 -93.3727 -87.0994 -5.08282 -1.8718L155.444 153.088L-5.08282 308.047V307.989ZM303.572 188.936C283.566 208.249 250.802 208.249 230.736 188.936L192.94 152.452L230.077 116.603C250.443 96.9437 283.806 96.9437 304.171 116.603L341.308 152.452L303.512 188.936H303.572Z' fill='%23F28482' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        left: 0;
        top: 0;
        height: 400px;
        width: 500px;
    }
    &::after {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        right: 0;
        bottom: 0;
        height: 345px;
        width: 519px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='519' height='346' viewBox='0 0 519 346' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M115.543 356.178C178.915 417.352 282.658 417.352 346.031 356.178L444.084 261.525L481.64 297.779C512.188 327.268 562.203 327.268 592.751 297.779C623.299 268.29 623.299 220.01 592.751 190.522C562.203 161.033 512.188 161.033 481.64 190.522L443.545 227.296L344.054 131.255C281.76 71.1219 179.813 71.1219 117.519 131.255L-0.00108488 244.7L115.483 356.178L115.543 356.178ZM499.49 207.694C520.274 187.631 554.237 187.631 574.961 207.694C595.746 227.758 595.746 260.542 574.961 280.548C554.177 300.612 520.215 300.612 499.49 280.548L461.754 244.121L499.49 207.694ZM135.009 148.775C187.72 97.8929 273.913 97.8929 326.624 148.775L425.995 244.7L328.301 339.005C274.692 390.755 186.941 390.755 133.332 339.005L35.6384 244.7L135.009 148.775Z' fill='%23F28482' fill-opacity='0.1'/%3E%3Cpath d='M175.919 297.776C206.108 326.918 255.584 326.918 285.772 297.776L323.508 261.349L484.634 416.887C583.107 511.944 744.173 511.944 842.646 416.887C941.118 321.83 941.118 166.35 842.646 71.2927C744.173 -23.7644 583.107 -23.7644 484.634 71.2927L322.969 227.351L284.874 190.577C255.164 161.898 206.587 161.898 176.937 190.577L120.933 244.639L175.979 297.776L175.919 297.776ZM503.083 89.1593C591.373 3.93167 735.787 3.93169 824.077 89.1594C912.367 174.387 912.367 313.793 824.077 399.02C735.787 484.248 591.373 484.248 503.083 399.02L342.556 244.061L503.083 89.1015L503.083 89.1593ZM194.428 208.212C214.434 188.9 247.198 188.9 267.264 208.212L305.06 244.697L267.923 280.546C247.557 300.205 214.194 300.205 193.829 280.546L156.692 244.697L194.488 208.212L194.428 208.212Z' fill='%23F28482' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
    }
    .big_blog_sec {
        h4 {
            font-size: 32px;
            font-weight: 700;
            margin: 30px 0 30px 0;
        }
        .red_min {
            font-size: 18px;
            color: #242430;
        }
        .eclip {
            font-size: 44px;
            line-height: 0;
            position: relative;
            top: -4px;
        }
    }
}

.get_start_pg {
    background-color: #F5CAC3;
    padding: 50px;
    .navbar {
        position: fixed;
        right: 90px;
        top: 75px;
    }
    #main-navigation {
        position: absolute;
        right: 40px;
        width: 180px;
        border: 1px solid #BCBCBC;
        top: 37px;
        border-radius: 20px;
        padding: 23px 26px;
        background-color: #fff;
        a {
            font-size: 14px;
            color: #84A59D;
            font-weight: 500;
            border-bottom: 1px solid #E3E3E3;
            margin-bottom: 14px;
            padding-bottom: 10px;
            padding-top: 0;
        }
    }
    .navbar-toggler {
        border-radius: 0;
        border: none;
        height: 20px;
        width: 27px;
        padding: 0;
        transition: 0.5s;
        z-index: 99999;
        i {
            width: 35px;
            height: 3px;
            display: block;
            margin-bottom: 8px;
            background: #000;
            transition: 0.5s;
            transform-origin: 0;
        }
    }
}

.gt_strat_sec {
    border-radius: 20px;
    padding: 60px;
    p {
        font-weight: 500;
        font-size: 18px;
        color: #4E4E4E;
        img {
            position: relative;
            margin-top: -22px;
            padding-left: 10px;
        }
    }
}

.gt_strat_in {
    p {
        font-weight: 400;
        font-size: 20px;
        color: #000000;
        margin-top: 40px;
    }
}

.event_btday {
    padding-top: 40px;
}

.event_btday_in {
    .event_btday_ban {
        @include bg-cover();
        min-height: 270px;
        position: relative;
        border-radius: 12px;
        padding: 80px 20px;
        margin-bottom: 60px;
        @include down-lg {
            padding: 58px 18px;
            min-height: 200px;
            margin-bottom: 30px;
        }
        h1 {
            font-size: 78px;
            font-weight: 600;
            @include down-lg {
                font-size: 60px;
            }
            @include down-md {
                font-size: 35px;
            }
        }
        .dte {
            display: block;
            width: 100%;
        }
    }
    .bt_left {
        h4 {
            font-weight: 600;
            font-size: 38px;
            color: #000000;
            margin-bottom: 11px;
            @include down-lg {
                font-size: 28px;
            }
            @include down-md {
                font-size: 25px;
            }
        }
        h6 {
            font-weight: 400;
            font-size: 21px;
            color: #8E8E8E;
            margin-bottom: 30px;
        }
        p {
            font-size: 20px;
            color: #3F3F3F;
            line-height: 27px;
            @include down-md {
                font-size: 16px;
            }
        }
    }
    .bt_right {
        .lnk {
            display: block;
            background-color: #F28482;
            border-radius: 5px;
            font-weight: 700;
            font-size: 23px;
            color: #fff;
            padding: 19px 10px;
            margin-bottom: 30px;
            text-align: center;
        }
        h5 {
            font-weight: 400;
            font-size: 21px;
            color: #373737;
        }
        h6 {
            font-weight: 400;
            font-size: 17px;
            color: #3F3F3F;
            margin-bottom: 30px;
        }
    }
}

.bt_join_us {
    background-color: #84A59D;
    padding: 60px 0 50px;
    @include down-md {
        padding: 30px 0 20px;
    }
    .lnk {
        display: block;
        background-color: #fff;
        border-radius: 5px;
        font-weight: 700;
        font-size: 23px;
        color: #84A59D;
        padding: 19px 10px;
        margin-bottom: 30px;
    }
    h2 {
        font-weight: 600;
        font-size: 55px;
        margin-bottom: 25px;
        @include down-lg {
            font-size: 40px;
        }
        @include down-md {
            font-size: 30px;
        }
    }
    p {
        font-weight: 400;
        font-size: 17px;
        margin-bottom: 30px;
    }
}

.form_bt_in {
    border: 1px solid #ECECEC;
    box-shadow: 2px 2px 20px rgb(0 0 0 / 40%);
    padding: 50px;
    label {
        color: #1A1A1A;
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .txt-2 {
        font-size: 15px;
        color: #B9B9B9;
        border: none;
        border-bottom: 2px solid #EEEEEE;
        border-radius: 0;
        padding: 10px 10px 10px 0;
    }
    p {
        color: #1A1A1A;
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 15px;
    }
    .form-check {
        label {
            color: #1A1A1A;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 5px;
        }
        .form-check-input:checked {
            background-color: #334999;
            border-color: #334999;
        }
    }
}

.smit4 {
    border-radius: 5px;
    background-color: #F28482;
    color: #fff;
    padding: 15px 57px;
    border: none;
}

.breadcrumb-1 {
    a {
        font-size: 12px;
        color: #000000;
        font-weight: 600;
        padding: 0 15px;
    }
    .active {
        a {
            color: #F28482;
        }
    }
}

.c-8E8E8E {
    color: #8E8E8E;
}
  

.landing-2-v1 {
    height: 640px;
    h1,
    h2 {
        color: #fff;
        font-size: 43px;
        font-weight: 500;
        padding-bottom: 50px;
        font-family: "KoHo", serif;
        margin: 0;
    }
    .top_ban_1 {
        width: 53%;
        align-items: flex-end;
        &::after {
            display: none;
        }
    }
    .top_ban_2 {
        width: 53%;
        clip-path: polygon(11% 0%, 100% 0%, 100% 100%, 0% 100%);
        align-items: flex-end;
        &::after {
            display: none;
        }
    }
}


.landing-2 {
    min-height: 465px;
    background-size: cover;
    position: relative;
    margin-top: 75px;
    padding-top: 125px;
    padding-bottom: 75px;
    overflow: hidden;
    h1 {
        font-family: KoHo, serif;
        color: #000;
        font-size: 60px;
        font-weight: 600;
        margin-bottom: 25px;
        line-height: 70px;
    }
    p {
        font-family: 'Poppins', sans-serif;
        line-height: 30px;
        margin-bottom: 25px;
        color: #000;
    }
}

.festivals_events {
    position: relative;
    .festivals_events_top {
        h3 {
            font-family: KoHo, serif;
            color: #313131;
            font-size: 37px;
            margin-bottom: 25px;
            line-height: normal;
        }
        p {
            color: #000000;
        }
    }
    .feat_vendor_box_1 {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 12px;
        overflow: hidden;
        h6 {
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            padding-left: 30px;
            padding-right: 15px;
            @include down-md {
                font-size: 16px;
                font-weight: 600;
                padding-left: 18px;
            }
        }
        .feat_vendor_box_img {
            img {
                border-radius: 60px 8px 8px 0px;
            }
        }
        .feat_vendor_box_lft {
            width: 215px;
        }
    }
    .sdfc {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='578' height='489' viewBox='0 0 578 489' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M115.794 356.178C179.167 417.352 282.91 417.352 346.282 356.178L444.336 261.525L481.892 297.779C512.44 327.268 562.455 327.268 593.003 297.779C623.551 268.29 623.551 220.01 593.003 190.522C562.455 161.033 512.44 161.033 481.892 190.522L443.797 227.296L344.306 131.255C282.012 71.1219 180.065 71.1219 117.771 131.255L0.250868 244.7L115.735 356.178L115.794 356.178ZM499.742 207.694C520.526 187.631 554.489 187.631 575.213 207.694C595.998 227.758 595.998 260.542 575.213 280.548C554.429 300.612 520.466 300.612 499.742 280.548L462.006 244.121L499.742 207.694ZM135.261 148.775C187.972 97.8929 274.165 97.8929 326.876 148.775L426.247 244.7L328.553 339.005C274.944 390.755 187.193 390.755 133.584 339.005L35.8904 244.7L135.261 148.775Z' fill='%23F28482' fill-opacity='0.1'/%3E%3Cpath d='M176.169 297.776C206.358 326.918 255.834 326.918 286.022 297.776L323.758 261.349L484.884 416.887C583.357 511.944 744.423 511.944 842.896 416.887C941.368 321.83 941.368 166.35 842.896 71.2927C744.423 -23.7644 583.357 -23.7644 484.884 71.2927L323.219 227.351L285.124 190.577C255.414 161.898 206.837 161.898 177.187 190.577L121.183 244.639L176.229 297.776L176.169 297.776ZM503.333 89.1593C591.623 3.93167 736.037 3.93169 824.327 89.1594C912.617 174.387 912.617 313.793 824.327 399.02C736.037 484.248 591.623 484.248 503.333 399.02L342.806 244.061L503.333 89.1015L503.333 89.1593ZM194.678 208.212C214.684 188.9 247.448 188.9 267.514 208.212L305.31 244.697L268.173 280.546C247.807 300.205 214.444 300.205 194.079 280.546L156.942 244.697L194.738 208.212L194.678 208.212Z' fill='%23F28482' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 500px;
        width: 578px;
        height: 489px;
    }
}

.diwali {
    background-color: #84A59D;
}

.birthdays {
    background-color: #F6BD60;
}

.eid-Al-Fitr {
    background-color: #F2BFAF;
}

.onam {
    background-color: #F7EDE2;
    h6 {
        color: #F2BFAF!important;
    }
}

.christmas {
    background-color: #009379;
}

.holi {
    background-color: #F5CAC3;
}

.lm2 {
    border-radius: 8px;
    display: inline-block;
    background-color: #009379;
    border: 4px solid #009379;
    font-size: 15px;
    font-weight: 700;
    font-family: "Inter", serif;
    margin-right: 10px;
    color: #fff;
    padding: 8px 52px;
}


.landing,
.resource_pg {
    .navbar {
        .navbar-nav {
            .nav-item {
                .nav-link {
                    padding: 5px 20px;
                    color: #000000;
                    font-size: 15px;
                    font-weight: 700;
                }
            }
        }
    }
    .dash-profile-menu {
        display: flex;
        .nav-link {
            margin: 0 8px;
        }
        .ui-bel {
            background-color: #F28482;
            border-radius: 50%;
            display: inline-flex;
            width: 34px;
            height: 34px;
            align-items: center;
            justify-content: center;
            color: #fff;
        }
        .ui-chat {
            background-color: #F28482;
            border-radius: 50px;
            display: inline-flex;
            font-size: 13px;
            font-weight: 600;
            color: #fff;
            padding: 10px 12px 9px;
            align-items: center;
            i {
                font-size: 20px;
                padding-right: 5px;
            }
        }
        .ui-wedding {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='41' height='24' viewBox='0 0 41 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.3529 6.94849C32.5937 4.19165 28.0768 4.19165 25.3176 6.94849L21.0485 11.214L19.4133 9.58026C18.0833 8.25134 15.9057 8.25134 14.5757 9.58026C13.2456 10.9092 13.2456 13.0849 14.5757 14.4138C15.9057 15.7428 18.0833 15.7428 19.4133 14.4138L21.072 12.7566L25.4037 17.0847C28.1159 19.7946 32.5546 19.7946 35.2668 17.0847L40.3836 11.9723L35.3555 6.94849H35.3529ZM18.6362 13.6399C17.7312 14.5441 16.2525 14.5441 15.3502 13.6399C14.4453 12.7358 14.4453 11.2583 15.3502 10.3568C16.2551 9.45258 17.7338 9.45258 18.6362 10.3568L20.2792 11.9984L18.6362 13.6399ZM34.5053 16.2952C32.2104 18.5882 28.4576 18.5882 26.1626 16.2952L21.8361 11.9723L26.0896 7.72238C28.4237 5.39028 32.2443 5.39028 34.5783 7.72238L38.8319 11.9723L34.5053 16.2952Z' fill='%2384A59D' stroke='%2384A59D' stroke-width='0.374732' stroke-miterlimit='10'/%3E%3Cpath d='M32.7247 9.5806C31.4103 8.26732 29.2562 8.26732 27.9418 9.5806L26.2988 11.2222L19.2835 4.21284C14.9961 -0.0709462 7.98344 -0.0709462 3.69603 4.21284C-0.591384 8.49662 -0.591384 15.5034 3.69603 19.7872C7.98344 24.0709 14.9961 24.0709 19.2835 19.7872L26.3223 12.7544L27.9809 14.4116C29.2744 15.704 31.3895 15.704 32.6804 14.4116L35.1188 11.9752L32.7221 9.5806H32.7247ZM18.4803 18.982C14.6362 22.8228 8.34854 22.8228 4.50447 18.982C0.660409 15.1412 0.660409 8.85882 4.50447 5.018C8.34854 1.17719 14.6362 1.17719 18.4803 5.018L25.4695 12.0013L18.4803 18.9846V18.982ZM31.9189 13.6168C31.0478 14.4871 29.6213 14.4871 28.7476 13.6168L27.1021 11.9726L28.719 10.3571C29.6056 9.47116 31.0582 9.47116 31.9449 10.3571L33.5619 11.9726L31.9163 13.6168H31.9189Z' fill='%2384A59D' stroke='%2384A59D' stroke-width='0.374732' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            display: inline-flex;
            background-position: left 10px center;
            border: 2px solid #84A59D;
            font-size: 13px;
            font-weight: 600;
            color: #84A59D;
            padding: 9px 16px 7px 57px;
            border-radius: 50px;
        }
    }
}

.landing-1 {
    min-height: 645px;
    background-size: cover;
    position: relative;
    margin-top: 75px;
    display: flex;
    align-items: end;
    padding-bottom: 75px;
    h1 {
        font-family: KoHo, serif;
        color: white;
        font-size: 60px;
        font-weight: 600;
        margin-bottom: 25px;
        line-height: 70px;
    }
    p {
        font-family: 'Poppins', sans-serif;
        line-height: 30px;
        margin-bottom: 25px;
        color: white;
    }
}

.cus {
    border-radius: 8px;
    display: inline-block;
    color: #F6BD60;
    border: 4px solid #F6BD60;
    font-size: 15px;
    font-weight: 700;
    font-family: "Inter", serif;
    padding: 8px 52px;
    text-decoration: none;
    cursor: pointer;
}

.lm1 {
    border-radius: 8px;
    display: inline-block;
    background-color: #84A59D;
    border: 4px solid #84A59D;
    font-size: 15px;
    font-weight: 700;
    font-family: "Inter", serif;
    margin-right: 10px;
    color: #fff;
    padding: 8px 52px;
}

.bg-DFDBD7 {
    background-color: #DFDBD7;
}

.ct-board-dash {
    h2 {
        font-size: 37px;
        color: #313131;
        margin-bottom: 25px;
        font-family: KoHo, serif;
    }
    p {
        color: #000000;
        margin-bottom: 75px;
    }
}

.timline-temp {
    .timline-tp {
        h2 {
            font-size: 37px;
            color: #313131;
            margin-bottom: 25px;
            font-family: KoHo, serif;
        }
        p {
            color: #000000;
            margin-bottom: 75px;
        }
    }
    .timline-bx {
        h5 {
            font-size: 38px;
            color: #182F43;
            margin-bottom: 25px;
            font-family: KoHo, serif;
        }
        p {
            color: #182F43;
            font-weight: 300;
            font-family: 'Poppins', sans-serif;
        }
    }
}

.img-hvr {
    position: relative;
    overflow: hidden;
    display: inline-block;
    a {
        display: inline-block;
        background-color: #F47920;
        border-radius: 8px;
        font-size: 15px;
        color: #fff;
        font-weight: 700;
        padding: 14px 55px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate( -50%, -50%);
        visibility: hidden;
        opacity: 0;
        transition: .5s;
    }
    &:hover {
        a {
            visibility: visible;
            opacity: 1;
        }
    }
}

.budget-temp {
    padding-top: 50px;
    padding-bottom: 35px;
}

.gallery-map {
    padding-top: 35px;
    padding-bottom: 70px;
}

.item-boxs-img {
    min-height: 620px;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    border-radius: 10px;
}

.ck-blogs {
    background-color: #F28482;
    position: relative;
    overflow: hidden;
    padding: 80px 0;
    .ck-blogs-img {
        position: absolute;
        left: calc(100vw / 2 - 1570px / 2 + 0px);
        height: 100%;
        width: 505px;
        top: 0;
    }
}

.landing-2 {
    min-height: 465px;
    background-size: cover;
    position: relative;
    margin-top: 75px;
    padding-top: 125px;
    padding-bottom: 75px;
    overflow: hidden;
    h1 {
        font-family: KoHo, serif;
        color: #000;
        font-size: 60px;
        font-weight: 600;
        margin-bottom: 25px;
        line-height: 70px;
    }
    p {
        font-family: KoHo, serif;
        line-height: 30px;
        margin-bottom: 25px;
        color: #000;
    }
}

.festivals_events {
    position: relative;
    .festivals_events_top {
        h3 {
            font-family: KoHo, serif;
            color: #313131;
            font-size: 37px;
            margin-bottom: 25px;
            line-height: normal;
        }
        p {
            color: #000000;
        }
    }
    .feat_vendor_box_1 {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 12px;
        overflow: hidden;
        h6 {
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            padding-left: 30px;
            padding-right: 15px;
            @include down-md {
                font-size: 16px;
                font-weight: 600;
                padding-left: 18px;
            }
        }
        .feat_vendor_box_img {
            img {
                border-radius: 60px 8px 8px 0px;
            }
        }
        .feat_vendor_box_lft {
            width: 215px;
        }
    }
    .sdfc {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='578' height='489' viewBox='0 0 578 489' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M115.794 356.178C179.167 417.352 282.91 417.352 346.282 356.178L444.336 261.525L481.892 297.779C512.44 327.268 562.455 327.268 593.003 297.779C623.551 268.29 623.551 220.01 593.003 190.522C562.455 161.033 512.44 161.033 481.892 190.522L443.797 227.296L344.306 131.255C282.012 71.1219 180.065 71.1219 117.771 131.255L0.250868 244.7L115.735 356.178L115.794 356.178ZM499.742 207.694C520.526 187.631 554.489 187.631 575.213 207.694C595.998 227.758 595.998 260.542 575.213 280.548C554.429 300.612 520.466 300.612 499.742 280.548L462.006 244.121L499.742 207.694ZM135.261 148.775C187.972 97.8929 274.165 97.8929 326.876 148.775L426.247 244.7L328.553 339.005C274.944 390.755 187.193 390.755 133.584 339.005L35.8904 244.7L135.261 148.775Z' fill='%23F28482' fill-opacity='0.1'/%3E%3Cpath d='M176.169 297.776C206.358 326.918 255.834 326.918 286.022 297.776L323.758 261.349L484.884 416.887C583.357 511.944 744.423 511.944 842.896 416.887C941.368 321.83 941.368 166.35 842.896 71.2927C744.423 -23.7644 583.357 -23.7644 484.884 71.2927L323.219 227.351L285.124 190.577C255.414 161.898 206.837 161.898 177.187 190.577L121.183 244.639L176.229 297.776L176.169 297.776ZM503.333 89.1593C591.623 3.93167 736.037 3.93169 824.327 89.1594C912.617 174.387 912.617 313.793 824.327 399.02C736.037 484.248 591.623 484.248 503.333 399.02L342.806 244.061L503.333 89.1015L503.333 89.1593ZM194.678 208.212C214.684 188.9 247.448 188.9 267.514 208.212L305.31 244.697L268.173 280.546C247.807 300.205 214.444 300.205 194.079 280.546L156.942 244.697L194.738 208.212L194.678 208.212Z' fill='%23F28482' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 500px;
        width: 578px;
        height: 489px;
    }
}

.diwali {
    background-color: #84A59D;
}

.birthdays {
    background-color: #F6BD60;
}

.eid-Al-Fitr {
    background-color: #F2BFAF;
}

.onam {
    background-color: #F7EDE2;
    h6 {
        color: #F2BFAF!important;
    }
}

.christmas {
    background-color: #009379;
}

.holi {
    background-color: #F5CAC3;
}

.lm2 {
    border-radius: 8px;
    display: inline-block;
    background-color: #009379;
    border: 4px solid #009379;
    font-size: 15px;
    font-weight: 700;
    font-family: "Inter", serif;
    margin-right: 10px;
    color: #fff;
    padding: 8px 52px;
    text-decoration: none;
    cursor:pointer;
}

.landing-2-v1 {
    height: 640px;
    h1,
    h2 {
        color: #fff;
        font-size: 43px;
        font-weight: 500;
        padding-bottom: 50px;
        font-family: "KoHo", serif;
        margin: 0;
    }
    .top_ban_1 {
        width: 53%;
        align-items: flex-end;
        &::after {
            display: none;
        }
    }
    .top_ban_2 {
        width: 53%;
        clip-path: polygon(11% 0%, 100% 0%, 100% 100%, 0% 100%);
        align-items: flex-end;
        &::after {
            display: none;
        }
    }
}

.sspe {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='679' height='489' viewBox='0 0 679 489' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M562.957 132.002C499.585 70.8275 395.842 70.8275 332.469 132.002L234.416 226.654L196.86 190.401C166.312 160.912 116.297 160.912 85.7489 190.401C55.2009 219.889 55.2009 268.169 85.7489 297.658C116.297 327.147 166.312 327.147 196.86 297.658L234.955 260.884L334.446 356.924C396.74 417.058 498.687 417.058 560.981 356.924L678.501 243.48L563.017 132.002H562.957ZM179.01 280.485C158.226 300.549 124.263 300.549 103.539 280.485C82.754 260.422 82.754 227.637 103.539 207.631C124.323 187.567 158.285 187.567 179.01 207.631L216.746 244.058L179.01 280.485ZM543.491 339.405C490.78 390.287 404.587 390.287 351.876 339.405L252.505 243.48L350.199 149.175C403.808 97.425 491.559 97.425 545.168 149.175L642.862 243.48L543.491 339.405Z' fill='%23F28482' fill-opacity='0.1'/%3E%3Cpath d='M502.581 190.403C472.392 161.262 422.916 161.262 392.728 190.403L354.992 226.83L193.866 71.2929C95.3931 -23.7643 -65.673 -23.7643 -164.146 71.2929C-262.618 166.35 -262.618 321.83 -164.146 416.887C-65.673 511.944 95.3931 511.944 193.866 416.887L355.531 260.829L393.626 297.603C423.336 326.282 471.913 326.282 501.563 297.603L557.567 243.541L502.521 190.403H502.581ZM175.417 399.02C87.1273 484.248 -57.2874 484.248 -145.577 399.02C-233.867 313.793 -233.867 174.387 -145.577 89.1595C-57.2874 3.93181 87.1273 3.93181 175.417 89.1595L335.944 244.119L175.417 399.078V399.02ZM484.072 279.968C464.066 299.28 431.302 299.28 411.236 279.968L373.44 243.483L410.577 207.634C430.943 187.975 464.306 187.975 484.671 207.634L521.808 243.483L484.012 279.968H484.072Z' fill='%23F28482' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 120px;
    width: 679px;
    height: 489px;
}

.tlb {
    padding: 30px;
    h5 {
        color: #313131;
        font-size: 27px;
        margin-bottom: 25px;
        font-family: "KoHo", serif;
    }
    p {
        color: 000000;
        margin-bottom: 25px;
    }
}

.c-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    @include down-md {
        gap: 10px;
    }
    >:first-child {
        grid-column: span 2;
    }
    img {
        width: 100%;
    }
}



.m_6d731127.mantine-Stack-root.__m__-r8p a {
    text-decoration: none;
}

.m_6d731127.mantine-Stack-root a {
    text-decoration: none;
}



