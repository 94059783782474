.poppins-font {
    font-family: "KoHo", serif;
    line-height: normal;
}

.main-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 3px 0 0px 0;
    @include transition(.9s);
    background-color: #fff;

    @include down-xl {
        //padding: 40px 0 14px 0;
    }

    @include down-lg {
        padding: 0;
    }

    .navbar {
        .navbar-brand {

            // padding-top: 60px; 
            @media screen and (max-width: 991px) {
                img {
                    //width: 110px;
                }
            }
        }

        .navbar-nav {
            align-items: center;
            margin: 0px auto;

            @include down-lg {
                align-items: start;
            }

            .nav-item {
                position: relative;
                padding: 5px 0;

                @include down-lg {
                    padding: 8px 0px 8px 0;
                }

                &:first-child {}

                &:last-child {
                    a {}
                }

                .nav-link {
                    padding: 5px 30px;
                    color: #3F3F3F;
                    position: relative;
                    font-size: 18px;
                    font-weight: 500;

                    @include down-xl {
                        padding: 5px 24px;
                    }

                    &:before {
                        content: '';
                        height: 2px;
                        background-color: #F28482 !important;
                        position: absolute;
                        opacity: 0;
                        left: 0;
                        right: 0;
                        bottom: 0px;
                        width: 70%;
                        margin: 0 auto;

                        @media screen and (max-width: 991px) {
                            bottom: -3px;
                            margin-left: 20px;
                        }
                    }

                    &:hover,
                    &.active {
                        &:before {
                            opacity: 1;
                        }
                    }

                    &:hover {
                        color: #F28482 !important;
                        cursor:pointer;
                    }

                    &.active {
                        color: #F28482 !important;
                    }
                }

                .drop-dropdown-icon {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.29289 9.83211C7.68342 10.2226 8.31658 10.2226 8.70711 9.83211L15.0711 3.46815C15.4616 3.07762 15.4616 2.44446 15.0711 2.05393C14.6805 1.66341 14.0474 1.66341 13.6569 2.05393L8 7.71079L2.34315 2.05393C1.95262 1.66341 1.31946 1.66341 0.928932 2.05393C0.538408 2.44446 0.538408 3.07762 0.928932 3.46815L7.29289 9.83211ZM7 0.125L7 9.125L9 9.125L9 0.125L7 0.125Z' fill='white'/%3E%3C/svg%3E%0A");
                    position: absolute;
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 11px;
                    top: 23px;
                    right: 7px;
                    cursor: pointer;

                    @include down-xl {
                        top: 18px;
                        right: 0px;
                    }

                    @include down-lg {
                        right: 10px;
                    }
                }
            }

            .dropdown-menu {
                // width: 230px;
                // padding: 26px 29px;
                // background-color: #fff;
                // border-radius: 5px;
                // top: 70px;
                // border: none;
                box-shadow: 1px 0px 8px rgb(0 0 0 / 20%);
                position: absolute;
                width: 270px;
                top: 53px;
                border: none;
                display: block;
                opacity: 0;
                visibility: hidden;

                @include down-lg {
                    display: none;
                    //padding: 0 18px;
                    padding: 0 10px;
                    background: var(--primary1);
                    border-radius: 0px 4px 4px 4px;
                    margin-top: 13px;
                    position: relative;
                    left: 10px;
                    width: inherit;
                    top: 0;
                    opacity: 1;
                    visibility: visible;
                }

                li {
                    margin: 0 6px;
                }

                a {
                    color: #000;
                    font-size: 14px;
                    line-height: 19px;
                    padding: 10px 15px;
                    border-radius: 5px;
                    display: block;
                    font-weight: 500;

                    &.active,
                    &:hover {
                        color: #f0b2ff;
                        background-color: transparent;
                    }

                    @include down-lg {
                        color: #fff;
                        font-weight: 500;
                        padding: 18px 0;
                        border: none;
                    }
                }

                @include down-lg {
                    li:not(:last-of-type) a {
                        border-bottom: 1px solid rgb(255 255 255 / 10%)
                    }
                }

                &:before {
                    //content: '';
                    position: absolute;
                    top: -3px;
                    left: 43px;
                    width: 26px;
                    height: 26px;
                    background-color: #fff;
                    z-index: -1;
                    transform: rotate(45deg);

                    @include down-lg {
                        top: 44px;
                        left: 66px;
                        display: none;
                    }
                }
            }

            .dropdown {
                &:hover {
                    @media screen and (min-width: 992px) {
                        .dropdown-menu {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

                @media screen and (max-width:991px) {
                    &.ui-active {
                        .dropdown-menu {
                            display: block;
                        }
                    }
                }

                &.active {
                    a {
                        &:before {
                            left: 10px;

                            @media screen and (min-width: 992px) {
                                width: 72%;
                                opacity: 1;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }

            @include down-lg {
                display: block;
            }
        }
    }

    #main-navigation {
        @include down-xl {}

        @include down-lg {
            // display: block;
            // position: absolute;
            // left: -900px;
            // max-width: calc(100% - 30px);
            // top: 0;
            // width: 300px;
            // background-color: #ed1c24;
            // padding: 5px 5px 21px 5px;
            // visibility: hidden;
            // opacity: 0;
            // transition: .5s;
            // height: calc(100vh - -10px);
            // overflow: auto;
            // &.active {
            //     left: 0;
            //     visibility: visible;
            //     opacity: 1;
            // }
            position: absolute;
            top: 0;
            left: 0%;
            height: calc(100vh - -10px);
            background: var(--primary1);
            clip-path: circle(0px at top left);
            transition: clip-path ease-in-out 700ms;
            width: 80%;

            &.active {
                clip-path: circle(250% at top left);
            }
        }
    }

    .navbar-toggler {
        @include down-lg {
            position: relative;
            border-radius: 0;
            right: 0px;
            border: none;
            height: 20px;
            width: 27px;
            padding: 0;
            transition: 0.5s;
            z-index: 99999;
            top: 0px;

            i {
                width: 27px;
                height: 2px;
                display: block;
                margin-bottom: 8px;
                background: #fff;
                transition: 0.5s;
                transform-origin: 0;

                &:nth-child(2) {
                    width: 20px;
                }
            }

            &.active {
                transform: rotate(2deg);

                i {
                    &:nth-child(1) {
                        transform: rotate(46deg);
                        background-color: #ed1c24;
                    }

                    &:nth-child(2) {
                        opacity: 0;
                    }

                    &:nth-child(3) {
                        transform: rotate(312deg);
                        background-color: #ed1c24;
                    }
                }
            }
        }
    }

    .dash-level-two-menu {
        background-color: #FFE6E5;
        padding: 15px 0 20px;

        .nav-link {
            color: #9B9B9B;
            font-size: 14px;
            padding: 8px 16px;
            position: relative;

            &:before {
                content: '';
                height: 2px;
                background-color: #F28482 !important;
                position: absolute;
                opacity: 0;
                left: 0;
                right: 0;
                bottom: 0px;
                width: 80%;
                margin: 0 auto;
            }

            &.active {
                color: #D26967;

                &:before {
                    opacity: 1;
                }
            }
        }
    }
}

.sg_up {
    display: inline-block;
    font-weight: 600;
    border: 1px solid #F28482;
    background-color: #F28482;
    border-radius: 100px;
    padding: 0px 30px;
    color: #fff;
    margin-right: 25px;
    text-transform: uppercase;

    &:hover {
        color: #F28482;
        background-color: #fff;
    }
}

.sg_up_dropdown {
    position: absolute;
    right: 0px;
    top: 62px;
    background: #f1f1f1;
    padding: 4px;
    display: flex;
    flex-direction: column;
    list-style: none;
    border-radius: 8px;
    li{
        color: #000;
        font-weight: 400;
        padding: 8px;
        &:hover{
            background-color: rgb(231, 231, 231);
        }
    }
    a{
        color: #000;
        text-decoration: none;
        font-size: 14px;
    }
}


.zoom-effect {
    transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
    cursor: pointer; /* Pointer cursor to indicate that the text is clickable */
  }
  
  .zoom-effect:hover {
    transform: scale(1.1); /* Zoom in effect */
  }
  

.mantine-Menu-dropdown a {
    text-decoration: none;
}

.mantine-Menu-item {
    padding: 10px 20px;
}

.lg_in {
    display: inline-block;
    color: #F28482;
    font-weight: 600;
    border: 1px solid #F28482;
    border-radius: 100px;
    padding: 0px 30px;
    text-transform: uppercase;

    &:hover {
        color: #fff;
        background-color: #F28482;
    }

}

.navbar-brand {
    img {
        @include transition(.5s);
    }
}

@include down-lg {
    .navbar-brand {
        img {
            max-width: 70%;
        }
    }

    .main-header .navbar .navbar-nav .nav-item .nav-link {
        font-size: 20px;
    }
}

.align-st {
    align-items: start !important;
}

.header-fix {
    background-color: #000;
    border-bottom: 1px solid #f1f1f1;
    padding-top: 0;

    .navbar-brand {
        img {
            max-width: 70%;
        }
    }
}

.header-fix {
    .drop-dropdown-icon {}

    .navbar .navbar-nav .nav-item {
        // color: #fff;
    }

    .navbar .navbar-nav .nav-item .nav-link {
        //color: #fff;
    }

    .dp-down {
        top: 54px;
    }

    @include up-lg {
        .navbar .navbar-nav .dropdown-menu {
            top: 50px;
        }
    }
}

.dp-down {
    //display: none;
    color: #fff;
    position: absolute;
    top: 62px;
    left: 70px;
    width: 26px;
    height: 26px;
    background-color: #fff;
    z-index: -1;
    transform: rotate(45deg);
    box-shadow: 1px 0px 8px rgb(0 0 0 / 20%);
    opacity: 0;

    @include down-lg {
        display: block;
        // width: 15px;
        // height: 15px;
        // position: absolute;
        // left: 180px;
        // top: 20px;
        // z-index: 9;
        // content: "\f107";
        // font-family: FontAwesome;
        // color: #fff;
    }
}

.main-header .navbar .navbar-nav .dropdown:hover .dp-down {
    opacity: 1;
}

.inmenu {
    .calandy {
        background-color: #000;
    }
}

.inner-body {
    .main-header:not(.header-fix) .navbar .navbar-nav .nav-item .nav-link {
        color: #fff;
    }

    .main-header:not(.header-fix) .navbar .navbar-nav .nav-item:last-child a {
        background-color: #fff;
        border: 1px solid #fff;
        color: #000000;
    }

    .dp-down {
        color: #fff;
    }

    .main-header .navbar-toggler i {
        // background: #00182e;
    }
}

.mobile-menu {
    overflow: hidden;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 50%);
        z-index: 9;
    }
}

.sc-top {
    position: fixed;
    bottom: 0;
    right: 15px;
    z-index: 9;
    opacity: 0;

    &.active {
        opacity: 1;
    }

    a {
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
        position: relative;

        &:after {
            content: "\f106";
            font: normal normal normal 14px/1 FontAwesome;
            position: absolute;
            padding: 5px;
            text-align: center;
            font-size: 17px;
            color: #336FE3;
            width: 100%;
            height: 100%;
        }

        &:hover {
            box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 50%) !important;
        }
    }
}

.dropdown a:after {
    display: none;
}



//Custom css

.mantine-Button-section {

    svg {
        &:hover {
            color:#F28482;
        }
    }
   
}

.m_38a85659 {

    .mantine-Menu-dropdown {
        button {
            font-size:14px;
        }
    }

}

