$screen-mobile-xs: 380;
$screen-mobile-sm: 480;
$screen-mobile: 575;
$screen-mobile-landscape: 767;
$screen-tablet: 991;
$screen-tablet-landscape: 1199;
$screen-desktop-md: 1299;
$screen-desktop-md1: 1399;
$screen-mac: 1599;
@mixin down-xxl {
   @media (max-width: #{$screen-desktop-md1}px) {
      @content;
   }
}

@mixin down-xxxl {
   @media (max-width: #{$screen-desktop-md}px) {
      @content;
   }
}

@mixin down-xl {
   @media (max-width: #{$screen-tablet-landscape}px) {
      @content;
   }
}

@mixin down-lg {
   @media (max-width: #{$screen-tablet}px) {
      @content;
   }
}

@mixin down-md {
   @media (max-width: #{$screen-mobile-landscape}px) {
      @content;
   }
}

@mixin down-sm {
   @media (max-width: #{$screen-mobile}px) {
      @content;
   }
}

@mixin up-sm {
   @media (min-width: 575px) {
      @content;
   }
}

@mixin up-md {
   @media (min-width: 768px) {
      @content;
   }
}

@mixin up-lg {
   @media (min-width: 992px) {
      @content;
   }
}

@mixin up-xl {
   @media (min-width: 1200px) {
      @content;
   }
}

@mixin up-xxl {
   @media (min-width: 1400px) {
      @content;
   }
}

@mixin up-xxxl {
   @media (min-width: 1550px) {
      @content;
   }
}

@mixin breakpoint($point) {
   @if $point==sm {
      @media (min-width: 576px) {
         @content ;
      }
   }
}