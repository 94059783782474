 .footer-v1 {
     background-image: url(/images/images/foot-shade.png);
     background-repeat: no-repeat;
     background-position: bottom right;
     background-color: #84A59D;
     padding: 80px 0;
     .slogan {
         font-size: 13px;
         line-height: 24px;
         letter-spacing: -0.1px;
         padding: 30px 0;
         margin: 0;
         color: #fff;
     }
     .lev-1 {
         font-size: 15px;
         line-height: 24px;
         margin-bottom: 20px;
         letter-spacing: -0.1px;
         font-weight: 500;
         display: block;
         color: #fff;
         text-decoration: none;
        
     }
     .lev-2 {
         display: block;
         font-size: 16px;
         line-height: 24px;
         margin-bottom: 20px;
         letter-spacing: -0.1px;
         font-weight: 300;
         color: #fff;
         text-decoration: none;
       
     }

     a {
        text-decoration:none;
     }
 }
 
 .venu {
     background-color: #F6BD60;
 }
 
 .ptograp {
     background-color: #84A59D;
 }
 
 .makup {
     background-color: #F7EDE2;
     h6 {
         color: #A5917C!important;
     }
 }
 
 .wedshoot {
     background-color: #F8CBB7;
 }
 
 .planing {
     background-color: #F5CAC3;
 }
 
 .bridal {
     background-color: #009379;
 }
 
 .featured_vendors {
     padding: 35px 0 65px 0;

     h3 {
        color:#212529;
     }
     
     a {
        text-decoration:none;
     }
     .feat_vendor_box {
         margin-bottom: 10px;
         display: flex;
         align-items: center;
         justify-content: space-between;
         border-radius: 12px;
         overflow: hidden;
         &_lft {
             width: 215px;
             h6 {
                 color: #fff;
                 font-size: 20px;
                 font-weight: 700;
                 padding-left: 30px;
                 padding-right: 15px;
             }

         }
     }
 }
 
 .subhead {
     font-size: 37px;
     font-weight: 600;
     font-family: "KoHo", serif;
     margin-bottom: 25px;
 }
 
 .plstag {
     color: #4E4E4E;
 }
 
 .home_resource {
     padding: 75px 0 35px 0;
 }
 
 .home_res_box {
     .home_res_box_img {
         background-repeat: no-repeat;
         height: 214px;
         border-radius: 8px;
         background-size: cover;
     }
     h6 {
         margin: 22px 0 14px;
         line-height: 35px;
         color: #182F43;
         font-size: 22px;
         font-family: Poppins;
         font-weight: 600;
     }
     p {
         line-height: 26px;
         color: #182F43;
         font-weight: 300;
         font-family: Poppins;
         margin-bottom: 8px;
     }
     .lm_more {
         font-family: Poppins;
         font-size: 14px;
         color: #009379;
         display: block;
     }
     a {
        text-decoration:none;
     }
 }
 
 .plan_celeberate {
     background-color: #FFF3F1;
     padding: 70px 0;
     background-image: url(/images/images/plan_celeberate.svg);
     background-repeat: no-repeat;
     background-position: top 56px center;
     .planhead {
         font-size: 96px;
         //font-family: "KoHo", serif;
         font-family: 'KoHo';
         color: #3F3F3F;
         line-height: 140px;
         img {
             position: relative;
             margin-top: -55px;
         }
     }
 }
 
 .home_plan_box {
     border-radius: 16px;
     background-size: cover;
     background-color: #fff;
     padding: 24px;
     height: 100%;
     .home_plan_box_img {
         background-repeat: no-repeat;
         height: 220px;
         border-radius: 12px;
         background-size: cover;
     }
     h6 {
         margin: 22px 0 16px;
         line-height: 35px;
         color: #000000;
         font-size: 22px;
         font-weight: 600;
         font-family: "KoHo", serif;
     }
     p {
         color: #182F43;
         font-weight: 300;
         font-family: Poppins;
         margin-bottom: 16px;
         font-size: 14px;
         min-height: 90px;
     }
     .gt_start {
         font-size: 15px;
         color: #17B898;
         display: inline-block;
         border: 1px solid #009379;
         padding: 10px 50px;
         border-radius: 50px;
     }
     a {
        text-decoration:none;
     }
 }
 
 .walk_you {
     h4 {
         margin: 0 0 16px;
         line-height: 45px;
         color: #3F3F3F;
         font-size: 40px;
         font-weight: 600;
         font-family: "KoHo", serif;
     }
     p {
         color: #4E4E4E;
         margin-bottom: 50px;
     }
 }
 
 .home_sign_up {
     padding-top: 60px;
     h5 {
         margin: 0 0 24px;
         color: #000;
         font-size: 20px;
         font-weight: 600;
         font-family: "KoHo", serif;
     }
     p {
         color: #4E4E4E;
         margin-bottom: 25px;
     }
 }
 
 .sign_up_form {
     padding-bottom: 70px;
     .txt {
         border: 1px solid #C9C9C9;
         height: 55px;
         color: #B6B6B6;
         font-weight: 300;
         border-radius: 8px;
         padding: 15px;
         width: 100%;
     }
     .smit {
         padding: 13px;
         border-radius: 50px;
         background-color: #F28482;
         color: #fff;
         width: 100%;
         border: none;
     }
 }
 
 .hm_bx {
     background-repeat: no-repeat;
     background-size: cover;
     position: absolute;
     height: 640px;
     width: 100%;
     .tp_heads {
         position: absolute;
         width: 100%;
         bottom: 55px;
         text-align: center;
         color: #FFFFFF;
         font-size: 43px;
         font-family: "KoHo", serif;
     }
 }
 
 .top_ban {
     height: 640px;
     position: relative;
     margin-top: 80px;
 }
 
 .hm_bx_11 {
     width: 37%!important;
 }
 
 .hm_bx_22 {
     width: 37%!important;
     left: 37%!important;
 }
 
 .hm_bx_33 {
     width: 35%!important;
     right: 0;
 }
 
 .hm_bx_11,
 .hm_bx_22,
 .hm_bx_33 {
     height: 640px;
     background-repeat: no-repeat;
     background-size: cover;
     position: absolute;
     top: 0;
 }
 
 .hm_bx_1 {
     clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
     &::before {
         content: '';
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 180px;
         background: linear-gradient(0deg, rgba(173, 113, 81, 1) 0%, rgba(173, 113, 81, 0.50) 40%, rgba(173, 113, 81, 0) 100%);
     }
 }
 
 .hm_bx_2 {
     left: -11%;
     clip-path: polygon(11% 0%, 100% 0%, 87% 100%, 0% 100%);
     &::before {
         content: '';
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 180px;
         background: linear-gradient(0deg, rgba(98, 0, 16, 1) 0%, rgba(98, 0, 16, 0.5) 60%, rgba(98, 0, 16, 0) 100%);
     }
 }
 
 .hm_bx_3 {
     clip-path: polygon(11% 0%, 100% 0%, 100% 100%, 0% 100%);
     &::before {
         content: '';
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 180px;
         background: linear-gradient(0deg, rgba(17, 15, 24, 1) 0%, rgba(17, 15, 24, 0.50) 60%, rgba(17, 15, 24, 0) 100%);
     }
 }


 //wedding-landing-css

 .landing,
 .resource_pg {
     .navbar {
         .navbar-nav {
             .nav-item {
                 .nav-link {
                     padding: 5px 20px;
                     color: #000000;
                     font-size: 15px;
                     font-weight: 700;
                 }
             }
         }
     }
     .dash-profile-menu {
         display: flex;
         .nav-link {
             margin: 0 8px;
         }
         .ui-bel {
             background-color: #F28482;
             border-radius: 50%;
             display: inline-flex;
             width: 34px;
             height: 34px;
             align-items: center;
             justify-content: center;
             color: #fff;
         }
         .ui-chat {
             background-color: #F28482;
             border-radius: 50px;
             display: inline-flex;
             font-size: 13px;
             font-weight: 600;
             color: #fff;
             padding: 10px 12px 9px;
             align-items: center;
             i {
                 font-size: 20px;
                 padding-right: 5px;
             }
         }
         .ui-wedding {
             background-image: url("data:image/svg+xml,%0A%3Csvg width='41' height='24' viewBox='0 0 41 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.3529 6.94849C32.5937 4.19165 28.0768 4.19165 25.3176 6.94849L21.0485 11.214L19.4133 9.58026C18.0833 8.25134 15.9057 8.25134 14.5757 9.58026C13.2456 10.9092 13.2456 13.0849 14.5757 14.4138C15.9057 15.7428 18.0833 15.7428 19.4133 14.4138L21.072 12.7566L25.4037 17.0847C28.1159 19.7946 32.5546 19.7946 35.2668 17.0847L40.3836 11.9723L35.3555 6.94849H35.3529ZM18.6362 13.6399C17.7312 14.5441 16.2525 14.5441 15.3502 13.6399C14.4453 12.7358 14.4453 11.2583 15.3502 10.3568C16.2551 9.45258 17.7338 9.45258 18.6362 10.3568L20.2792 11.9984L18.6362 13.6399ZM34.5053 16.2952C32.2104 18.5882 28.4576 18.5882 26.1626 16.2952L21.8361 11.9723L26.0896 7.72238C28.4237 5.39028 32.2443 5.39028 34.5783 7.72238L38.8319 11.9723L34.5053 16.2952Z' fill='%2384A59D' stroke='%2384A59D' stroke-width='0.374732' stroke-miterlimit='10'/%3E%3Cpath d='M32.7247 9.5806C31.4103 8.26732 29.2562 8.26732 27.9418 9.5806L26.2988 11.2222L19.2835 4.21284C14.9961 -0.0709462 7.98344 -0.0709462 3.69603 4.21284C-0.591384 8.49662 -0.591384 15.5034 3.69603 19.7872C7.98344 24.0709 14.9961 24.0709 19.2835 19.7872L26.3223 12.7544L27.9809 14.4116C29.2744 15.704 31.3895 15.704 32.6804 14.4116L35.1188 11.9752L32.7221 9.5806H32.7247ZM18.4803 18.982C14.6362 22.8228 8.34854 22.8228 4.50447 18.982C0.660409 15.1412 0.660409 8.85882 4.50447 5.018C8.34854 1.17719 14.6362 1.17719 18.4803 5.018L25.4695 12.0013L18.4803 18.9846V18.982ZM31.9189 13.6168C31.0478 14.4871 29.6213 14.4871 28.7476 13.6168L27.1021 11.9726L28.719 10.3571C29.6056 9.47116 31.0582 9.47116 31.9449 10.3571L33.5619 11.9726L31.9163 13.6168H31.9189Z' fill='%2384A59D' stroke='%2384A59D' stroke-width='0.374732' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");
             background-repeat: no-repeat;
             display: inline-flex;
             background-position: left 10px center;
             border: 2px solid #84A59D;
             font-size: 13px;
             font-weight: 600;
             color: #84A59D;
             padding: 9px 16px 7px 57px;
             border-radius: 50px;
         }
     }
 }
 
 .landing-1 {
     min-height: 645px;
     background-size: cover;
     position: relative;
     margin-top: 75px;
     display: flex;
     align-items: end;
     padding-bottom: 75px;
     h1 {
         font-family: "Lora", serif;
         color: white;
         font-size: 60px;
         font-weight: 600;
         margin-bottom: 25px;
         line-height: 70px;
     }
     p {
         font-family: 'Poppins', sans-serif;
         line-height: 30px;
         margin-bottom: 25px;
         color: white;
     }
 }
 
 .cus {
     border-radius: 8px;
     display: inline-block;
     color: #F6BD60;
     border: 4px solid #F6BD60;
     font-size: 15px;
     font-weight: 700;
     font-family: "Inter", serif;
     padding: 8px 52px;
 }
 
 .lm1 {
     border-radius: 8px;
     display: inline-block;
     background-color: #84A59D;
     border: 4px solid #84A59D;
     font-size: 15px;
     font-weight: 700;
     font-family: "Inter", serif;
     margin-right: 10px;
     color: #fff;
     padding: 8px 52px;
     text-decoration: none;
 }
 
 .bg-DFDBD7 {
     background-color: #DFDBD7;
 }
 
 .ct-board-dash {
     h2 {
         font-size: 37px;
         color: #313131;
         margin-bottom: 25px;
         font-family: "Lora", serif;
     }
     p {
         color: #000000;
         margin-bottom: 75px;
     }
 }
 
 .timline-temp {
     .timline-tp {
         h2 {
             font-size: 37px;
             color: #313131;
             margin-bottom: 25px;
             font-family: "Lora", serif;
         }
         p {
             color: #000000;
             margin-bottom: 75px;
         }
     }
     .timline-bx {
         h5 {
             font-size: 38px;
             color: #182F43;
             margin-bottom: 25px;
             font-family: "Lora", serif;
         }
         p {
             color: #182F43;
             font-weight: 300;
             font-family: 'Poppins', sans-serif;
         }
     }
 }
 
 .img-hvr {
     position: relative;
     overflow: hidden;
     display: inline-block;
     a {
         display: inline-block;
         background-color: #F47920;
         border-radius: 8px;
         font-size: 15px;
         color: #fff;
         font-weight: 700;
         padding: 14px 55px;
         position: absolute;
         left: 50%;
         top: 50%;
         transform: translate( -50%, -50%);
         visibility: hidden;
         opacity: 0;
         transition: .5s;
         text-decoration: none;
     }
     &:hover {
         a {
             visibility: visible;
             opacity: 1;
         }
     }
 }
 
 .budget-temp {
     padding-top: 50px;
     padding-bottom: 35px;
 }
 
 .gallery-map {
     padding-top: 35px;
     padding-bottom: 70px;
 }
 
 .item-boxs-img {
     min-height: 620px;
     background-repeat: no-repeat;
     background-size: cover;
     overflow: hidden;
     border-radius: 10px;
 }
 
 .ck-blogs {
     background-color: #F28482;
     position: relative;
     overflow: hidden;
     padding: 80px 0;
     .ck-blogs-img {
         position: absolute;
         left: calc(100vw / 2 - 1570px / 2 + 0px);
         height: 100%;
         width: 505px;
         top: 0;
     }
 }



 .footer-v1 {
    background-image: url(/images/images/foot-shade.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-color: #84A59D;
    padding: 80px 0;
    .slogan {
        font-size: 13px;
        line-height: 24px;
        letter-spacing: -0.1px;
        padding: 30px 0;
        margin: 0;
        color: #fff;
    }
    .lev-1 {
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 20px;
        letter-spacing: -0.1px;
        font-weight: 500;
        display: block;
        color: #fff;
    }
    .lev-2 {
        display: block;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
        letter-spacing: -0.1px;
        font-weight: 300;
        color: #fff;
    }
    .log-foot-menu {
        padding: 50px 0;
        a {
            display: inline-block;
            color: #fff;
            padding: 0 10px;
            font-family: "Inter", serif;
            font-size: 15px;
            font-weight: 700;
        }
    }
    .log-cpyright {
        color: #fff;
        font-family: "Lora", serif;
        font-size: 14px;
    }
}

.venu {
    background-color: #F6BD60;
}

.ptograp {
    background-color: #84A59D;
}

.makup {
    background-color: #F7EDE2;
    h6 {
        color: #A5917C!important;
    }
}

.wedshoot {
    background-color: #F8CBB7;
}

.planing {
    background-color: #F5CAC3;
}

.bridal {
    background-color: #009379;
}

.featured_vendors {
    padding: 35px 0 65px 0;
    .feat_vendor_box {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 12px;
        overflow: hidden;
        &_lft {
            width: 215px;
            h6 {
                color: #fff;
                font-size: 20px;
                font-weight: 700;
                padding-left: 30px;
                padding-right: 15px;
            }
        }
    }
}

.subhead {
    font-size: 37px;
    font-weight: 600;
    font-family: "KoHo", serif;
    margin-bottom: 25px;
}

.plstag {
    color: #4E4E4E;
}

.home_resource {
    padding: 75px 0 35px 0;
}

.home_res_box {
    .home_res_box_img {
        background-repeat: no-repeat;
        height: 214px;
        border-radius: 8px;
        background-size: cover;
    }
    h6 {
        margin: 22px 0 14px;
        line-height: 35px;
        color: #182F43;
        font-size: 22px;
        font-family: Poppins;
        font-weight: 600;
    }
    p {
        line-height: 26px;
        color: #182F43;
        font-weight: 300;
        font-family: Poppins;
        margin-bottom: 8px;
    }
    .lm_more {
        font-family: Poppins;
        font-size: 14px;
        color: #009379;
        display: block;
    }
}

.plan_celeberate {
    background-color: #FFF3F1;
    padding: 70px 0;
    background-image: url(/images/images/plan_celeberate.svg);
    background-repeat: no-repeat;
    background-position: top 56px center;
    .planhead {
        font-size: 96px;
        font-family: "KoHo", serif;
        color: #3F3F3F;
        line-height: 140px;
        img {
            position: relative;
            margin-top: -55px;
        }
    }
}

.home_plan_box {
    border-radius: 16px;
    background-size: cover;
    background-color: #fff;
    padding: 24px;
    height: 100%;
    .home_plan_box_img {
        background-repeat: no-repeat;
        height: 220px;
        border-radius: 12px;
        background-size: cover;
    }
    h6 {
        margin: 22px 0 16px;
        line-height: 35px;
        color: #000000;
        font-size: 22px;
        font-weight: 600;
        font-family: "KoHo", serif;
    }
    p {
        color: #182F43;
        font-weight: 300;
        font-family: Poppins;
        margin-bottom: 16px;
        font-size: 14px;
        min-height: 90px;
    }
    .gt_start {
        font-size: 15px;
        color: #17B898;
        display: inline-block;
        border: 1px solid #009379;
        padding: 10px 50px;
        border-radius: 50px;
    }
}

.walk_you {
    h4 {
        margin: 0 0 16px;
        line-height: 45px;
        color: #3F3F3F;
        font-size: 40px;
        font-weight: 600;
        font-family: "KoHo", serif;
    }
    p {
        color: #4E4E4E;
        margin-bottom: 50px;
    }
}

.home_sign_up {
    padding-top: 60px;
    h5 {
        margin: 0 0 24px;
        color: #000;
        font-size: 20px;
        font-weight: 600;
        font-family: "KoHo", serif;
    }
    p {
        color: #4E4E4E;
        margin-bottom: 25px;
    }
}

.sign_up_form {
    padding-bottom: 70px;
    .txt {
        border: 1px solid #C9C9C9;
        height: 55px;
        color: #B6B6B6;
        font-weight: 300;
        border-radius: 8px;
        padding: 15px;
        width: 100%;
    }
    .smit {
        padding: 13px;
        border-radius: 50px;
        background-color: #F28482;
        color: #fff;
        width: 100%;
        border: none;
    }
}

.hm_bx {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 640px;
    width: 100%;
    .tp_heads {
        position: absolute;
        width: 100%;
        bottom: 55px;
        text-align: center;
        color: #FFFFFF;
        font-size: 43px;
        font-family: "KoHo", serif;
    }
}

.top_ban {
    height: 640px;
    position: relative;
    margin-top: 80px;
}

.hm_bx_11 {
    width: 37%;
}

.hm_bx_22 {
    width: 37%;
    left: 37%;
}

.hm_bx_33 {
    width: 35%;
    right: 0;
}

.hm_bx_11,
.hm_bx_22,
.hm_bx_33 {
    height: 640px;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
}

.hm_bx_1 {
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 180px;
        background: linear-gradient(0deg, rgba(173, 113, 81, 1) 0%, rgba(173, 113, 81, 0.50) 40%, rgba(173, 113, 81, 0) 100%);
    }
}

.hm_bx_2 {
    left: -11%;
    clip-path: polygon(11% 0%, 100% 0%, 87% 100%, 0% 100%);
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 180px;
        background: linear-gradient(0deg, rgba(98, 0, 16, 1) 0%, rgba(98, 0, 16, 0.5) 60%, rgba(98, 0, 16, 0) 100%);
    }
}

.hm_bx_3 {
    clip-path: polygon(11% 0%, 100% 0%, 100% 100%, 0% 100%);
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 180px;
        background: linear-gradient(0deg, rgba(17, 15, 24, 1) 0%, rgba(17, 15, 24, 0.50) 60%, rgba(17, 15, 24, 0) 100%);
    }
}

.top_ban_without_login {
    position: relative;
    height: 551px;
    .top_ban_1 {
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        width: 66.5%;
        background-position: right;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgb(29 100 100 / 50%);
            opacity: 0;
        }
    }
    .top_ban_2 {
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        background-position: center right 0px;
        clip-path: polygon(33% 0%, 100% 0%, 100% 100%, 0% 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(239, 71, 110, 0.5);
            opacity: 0;
        }
    }
}

.without_login_head {
    background-color: transparent;
    .navbar-brand {
        flex-grow: 1;
        text-align: center;
    }
    #main-navigation {
        flex-grow: inherit;
    }
}

.bx__down {
    opacity: 0;
    position: relative;
    z-index: 1;
    padding-left: 96px;
    padding-top: 20px;
    h1,
    h2 {
        color: #fff;
        font-size: 43px;
        font-weight: 600;
        margin-bottom: 20px;
    }
}

.top_ban_1 {
    a {
        display: inline-block;
        background-color: #F28482;
        border-radius: 50px;
        color: #fff;
        font-weight: 600;
        font-family: "Inter", serif;
        padding: 19px 78px;
        letter-spacing: -1px;
    }
    &:hover {
        .bx__down {
            opacity: 1;
        }
        &::after {
            opacity: 1;
        }
    }
}

.top_ban_2 {
    a {
        display: inline-block;
        background-color: #84A59D;
        border-radius: 50px;
        color: #fff;
        font-weight: 600;
        font-family: "Inter", serif;
        padding: 19px 78px;
        letter-spacing: -1px;
    }
    &:hover {
        .bx__down {
            opacity: 1;
        }
        &::after {
            opacity: 1;
        }
    }
}


.big_blog_sec img {
    border-radius: 50px;
}

.big_blog_sec p {
    text-align:left;
}


.big_blog_sec h3 {
    text-align:left;
}
.ct-board-dash {
    margin-top: 50px;
}

 