@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
@import "_base";
@import "_breakpoint";
@import "_typograpgy";
@import "_header";
@import "_home";
@import "_inner";
.koho-font {
    font-family: "KoHo", serif;
}

/* In styles/globals.css or your custom global stylesheet */

/* Inter Font */
@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Regular.woff2') format('woff2'),
         url('/fonts/Inter-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Bold.woff2') format('woff2'),
         url('/fonts/Inter-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  /* KoHo Font */
  @font-face {
    font-family: 'KoHo';
    src: url('/fonts/KoHo-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
  }
  
  /* Poppins Font */
 
  
 
  

//fonts