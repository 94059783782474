.closeButton {
  color: var(--mantine-color-white) !important;
}
.closeButton:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.mantine-Notifications-notification::before {
  background-color: none !important;
}
