@mixin pseudo ( $loc: before, $content: '', $position: absolute, $top: 0, $bottom: 0, $left: 0, $right: 0) {
    &::#{$loc} {
        content: $content;
        position: $position;
        top: $top;
        bottom: $bottom;
        left: $left;
        right: $right;
        @content;
    }
}


/* Transition */

@mixin transition($value) {
    -o-transition: #{$value};
    -moz-transition: #{$value};
    -ms-transition: #{$value};
    -webkit-transition: #{$value};
    transition: #{$value};
}

@mixin animate {
    -o-transition: 1s;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -webkit-transition: 1s;
    transition: 1s;
}

@mixin bg-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

a,
button {
    text-decoration: none;
    &:hover,
    &:focus {
        outline: none !important;
        box-shadow: none !important;
        text-decoration: none;
    }
}

img {
    max-width: 100%;
}

.error {
    border-color: #f00 !important;
}

@include up-xl {
    .container--1 {
        max-width: 1235px;
    }
    .nav-container-dt {
        max-width: 1235px;
    }
}

@include down-xl {
    .nav-container-dt {
        // max-width: 100%;
    }
}

@media screen and (min-width: 1550px) {
    .nav-container-dt {
        max-width: 1480px;
    }
    .container--1 {
        max-width: 1480px;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

@include down-xxl {
    @for $i from 5 through 30 {
        .res-pt-#{$i * 5} {
            padding-top: ($i * 4.7px);
        }
    }
}

@include down-xl {
    @for $i from 5 through 30 {
        .res-pt-#{$i * 5} {
            padding-top: ($i * 4.5px);
        }
    }
}

@include down-lg {
    @for $i from 5 through 30 {
        .res-pt-#{$i * 5} {
            padding-top: ($i * 4px);
        }
    }
}

@include down-md {
    @for $i from 5 through 30 {
        .res-pt-#{$i * 5} {
            padding-top: ($i * 3.5px);
        }
    }
}

@include down-sm {
    @for $i from 5 through 30 {
        .res-pt-#{$i * 5} {
            padding-top: ($i * 3px);
        }
    }
}

@for $i from 5 through 30 {
    .res-pb-#{$i * 5} {
        padding-bottom: ($i * 5px);
    }
}

@include down-xxl {
    @for $i from 5 through 30 {
        .res-pb-#{$i * 5} {
            padding-bottom: ($i * 4.7px);
        }
    }
}

@include down-xl {
    @for $i from 5 through 30 {
        .res-pb-#{$i * 5} {
            padding-bottom: ($i * 4.5px);
        }
    }
}

@include down-lg {
    @for $i from 5 through 30 {
        .res-pb-#{$i * 5} {
            padding-bottom: ($i * 4px);
        }
    }
}

@include down-md {
    @for $i from 5 through 30 {
        .res-pb-#{$i * 5} {
            padding-bottom: ($i * 3.5px);
        }
    }
}

@include down-sm {
    @for $i from 5 through 30 {
        .res-pb-#{$i * 5} {
            padding-bottom: ($i * 3px);
        }
    }
}

@mixin ui-res-pt($pt) {
    padding-top: $pt;
    @include down-xxl {
        padding-top: calc($pt/1.2);
    }
    @include down-xl {
        padding-top: calc($pt/1.5);
    }
    @include down-lg {
        padding-top: calc($pt/2);
    }
    @include down-md {
        padding-top: calc($pt/2.5);
    }
    @include down-sm {
        padding-top: calc($pt/3);
    }
}

@mixin ui-res-pb($pb) {
    padding-bottom: $pb;
    @include down-xxl {
        padding-bottom: calc($pb/1.2);
    }
    @include down-xl {
        padding-bottom: calc($pb/1.5);
    }
    @include down-lg {
        padding-bottom: calc($pb/2);
    }
    @include down-md {
        padding-bottom: calc($pb/2.5);
    }
    @include down-sm {
        padding-bottom: calc($pb/3);
    }
}

@mixin ui-res-pr($pr) {
    padding-right: $pr;
    @include down-xxl {
        padding-right: calc($pr/1.2);
    }
    @include down-xl {
        padding-right: calc($pr/1.5);
    }
    @include down-lg {
        padding-right: calc($pr/2);
    }
    @include down-md {
        padding-right: calc($pr/2.5);
    }
    @include down-sm {
        padding-right: calc($pr/3);
    }
}

@mixin ui-res-mt($mt) {
    margin-top: $mt;
    @include down-xxl {
        margin-top: calc($mt/1.2);
    }
    @include down-xl {
        margin-top: calc($mt/1.5);
    }
    @include down-lg {
        margin-top: calc($mt/2);
    }
    @include down-md {
        margin-top: calc($mt/2.5);
    }
    @include down-sm {
        margin-top: calc($mt/3);
    }
}

@mixin ui-res-mb($mb) {
    margin-bottom: $mb;
    @include down-xxl {
        margin-bottom: calc($mb/1.2);
    }
    @include down-xl {
        margin-bottom: calc($mb/1.5);
    }
    @include down-lg {
        margin-bottom: calc($mb/2);
    }
    @include down-md {
        margin-bottom: calc($mb/2.5);
    }
    @include down-sm {
        margin-bottom: calc($mb/3);
    }
}

.ui-pt-res-10 {
    @include ui-res-pt(10px);
}

.ui-pt-res-15 {
    @include ui-res-pt(15px);
}

.ui-pt-res-20 {
    @include ui-res-pt(20px);
}

.ui-pt-res-25 {
    @include ui-res-pt(25px);
}

.ui-pt-res-30 {
    @include ui-res-pt(30px);
}

.ui-pt-res-35 {
    @include ui-res-pt(35px);
}

.ui-pt-res-40 {
    @include ui-res-pt(40px);
}

.ui-pt-res-45 {
    @include ui-res-pt(45px);
}

.ui-pt-res-50 {
    @include ui-res-pt(50px);
}

.ui-pt-res-55 {
    @include ui-res-pt(55px);
}

.ui-pt-res-60 {
    @include ui-res-pt(60px);
}

.ui-pt-res-65 {
    @include ui-res-pt(65px);
}

.ui-pt-res-70 {
    @include ui-res-pt(70px);
}

.ui-pt-res-75 {
    @include ui-res-pt(75px);
}

.ui-pt-res-80 {
    @include ui-res-pt(80px);
}

.ui-pt-res-90 {
    @include ui-res-pt(90px);
}

.ui-pt-res-95 {
    @include ui-res-pt(95px);
}

.ui-pt-res-100 {
    @include ui-res-pt(100px);
}

.ui-pt-res-105 {
    @include ui-res-pt(105px);
}

.ui-pt-res-120 {
    @include ui-res-pt(120px);
}

.ui-pb-res-10 {
    @include ui-res-pb(10px);
}

.ui-pb-res-15 {
    @include ui-res-pb(15px);
}

.ui-pb-res-20 {
    @include ui-res-pb(20px);
}

.ui-pb-res-25 {
    @include ui-res-pb(25px);
}

.ui-pb-res-30 {
    @include ui-res-pb(30px);
}

.ui-pb-res-40 {
    @include ui-res-pb(40px);
}

.ui-pb-res-45 {
    @include ui-res-pb(45px);
}

.ui-pb-res-50 {
    @include ui-res-pb(50px);
}

.ui-pb-res-60 {
    @include ui-res-pb(60px);
}

.ui-pb-res-65 {
    @include ui-res-pb(65px);
}

.ui-pb-res-70 {
    @include ui-res-pb(70px);
}

.ui-pb-res-80 {
    @include ui-res-pb(80px);
}

.ui-pb-res-90 {
    @include ui-res-pb(90px);
}

.ui-pb-res-95 {
    @include ui-res-pb(95px);
}

.ui-pb-res-100 {
    @include ui-res-pb(100px);
}

.ui-pb-res-120 {
    @include ui-res-pb(120px);
}

.ui-pr-res-10 {
    @include ui-res-pr(10px);
}

.ui-pr-res-15 {
    @include ui-res-pr(15px);
}

.ui-pr-res-20 {
    @include ui-res-pr(20px);
}

.ui-pr-res-40 {
    @include ui-res-pr(40px);
}

.ui-pr-res-50 {
    @include ui-res-pr(50px);
}

.ui-pr-res-70 {
    @include ui-res-pr(70px);
}

.ui-pr-res-80 {
    @include ui-res-pr(80px);
}

.ui-pr-res-110 {
    @include ui-res-pr(110px);
}

.ui-mt-res-10 {
    @include ui-res-mt(10px);
}

.ui-mt-res-15 {
    @include ui-res-mt(15px);
}

.ui-mt-res-20 {
    @include ui-res-mt(20px);
}

.ui-mt-res-40 {
    @include ui-res-mt(40px);
}

.ui-mt-res-50 {
    @include ui-res-mt(50px);
}

.ui-mt-res-60 {
    @include ui-res-mt(60px);
}

.ui-mb-res-10 {
    @include ui-res-mb(10px);
}

.ui-mb-res-12 {
    @include ui-res-mb(12px);
}

.ui-mb-res-15 {
    @include ui-res-mb(15px);
}

.ui-mb-res-20 {
    @include ui-res-mb(20px);
}

.ui-mb-res-25 {
    @include ui-res-mb(25px);
}

.ui-mb-res-30 {
    @include ui-res-mb(30px);
}

.ui-mb-res-35 {
    @include ui-res-mb(35px);
}

.ui-mb-res-40 {
    @include ui-res-mb(40px);
}

.ui-mb-res-45 {
    @include ui-res-mb(45px);
}

.ui-mb-res-50 {
    @include ui-res-mb(50px);
}

.ui-mb-res-55 {
    @include ui-res-mb(55px);
}

.ui-mb-res-60 {
    @include ui-res-mb(60px);
}

.ui-mb-res-65 {
    @include ui-res-mb(65px);
}

.ui-mb-res-70 {
    @include ui-res-mb(70px);
}

.ui-mb-res-75 {
    @include ui-res-mb(75px);
}

.ui-mb-res-80 {
    @include ui-res-mb(80px);
}

.ui-mb-res-85 {
    @include ui-res-mb(85px);
}

.ui-mb-res-90 {
    @include ui-res-mb(90px);
}

.ui-mb-res-95 {
    @include ui-res-mb(95px);
}

.ui-mb-res-100 {
    @include ui-res-mb(100px);
}

.ui-mb-res-115 {
    @include ui-res-mb(115px);
}

.ui-mb-res-120 {
    @include ui-res-mb(120px);
}

@mixin fs-20 {
    @include down-md {
        font-size: 14px;
    }
    @include up-md {
        font-size: 16px;
    }
    @include up-lg {
        font-size: 18px;
    }
    @include up-xl {
        font-size: 20px;
    }
}

@mixin fs-24 {
    @include down-md {
        font-size: 18px;
    }
    @include up-md {
        font-size: 20px;
    }
    @include up-lg {
        font-size: 22px;
    }
    @include up-xl {
        font-size: 24px;
    }
}

@mixin fs-30 {
    @include down-md {
        font-size: 18px;
    }
    @include up-md {
        font-size: 24px;
    }
    @include up-lg {
        font-size: 26px;
    }
    @include up-xl {
        font-size: 30px;
    }
}

@mixin fs-32 {
    @include down-md {
        font-size: 24px;
    }
    @include up-md {
        font-size: 28px;
    }
    @include up-lg {
        font-size: 30px;
    }
    @include up-xl {
        font-size: 32px;
    }
}

@mixin fs-36 {
    @include down-md {
        font-size: 24px;
    }
    @include up-md {
        font-size: 28px;
    }
    @include up-lg {
        font-size: 32px;
    }
    @include up-xl {
        font-size: 36px;
    }
}

@mixin fs-40 {
    @include down-md {
        font-size: 23px;
    }
    @include up-md {
        font-size: 30px;
    }
    @include up-lg {
        font-size: 35px;
    }
    @include up-xl {
        font-size: 40px;
    }
}

@mixin fs-45 {
    @include down-md {
        font-size: 25px;
    }
    @include up-md {
        font-size: 30px;
    }
    @include up-lg {
        font-size: 35px;
    }
    @include up-xl {
        font-size: 45px;
    }
}

@mixin fs-50 {
    @include down-md {
        font-size: 23px;
    }
    @include up-md {
        font-size: 30px;
    }
    @include up-lg {
        font-size: 35px;
    }
    @include up-xl {
        font-size: 50px;
    }
}

@mixin fs-60 {
    @include down-md {
        font-size: 32px;
    }
    @include up-md {
        font-size: 40px;
    }
    @include up-lg {
        font-size: 48px;
    }
    @include up-xl {
        font-size: 60px;
    }
}

@mixin fsize($value) {
    @if ($value > 30px) {
        @include down-md {
            font-size: calc($value / 1.8);
        }
        @include up-md {
            font-size: calc($value / 1.6);
        }
        @include up-lg {
            font-size: calc($value / 1.2);
        }
        @include up-xl {
            font-size: $value;
        }
    }
    @else {
        @include down-md {
            font-size: calc($value / 1.5);
        }
        @include up-md {
            font-size: calc($value / 1.4);
        }
        @include up-lg {
            font-size: calc($value / 1.2);
        }
        @include up-xl {
            font-size: $value;
        }
    }
}

.zi-9 {
    z-index: 9;
}

.fw-6 {
    font-weight: 600;
}

.fw-8 {
    font-weight: 800;
}

.res-a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.owl-carousel {
    .owl-item {
        img {
            width: auto;
        }
    }
}

.bg-black {
    background-color: #000;
}

.plset {
    padding-left: calc(100vw / 2 - 1300px / 2 + 12px);
    @include up-xxxl {
        padding-left: calc(100vw / 2 - 1300px / 2 + 12px);
    }
    @include down-xxl {
        padding-left: 15px;
    }
}

.pl {
    padding-left: calc(100vw / 2 - 1240px / 2 + 12px);
    @include up-xxxl {
        padding-left: calc(100vw / 2 - 1480px / 2 + 12px);
    }
    @include down-xxl {
        padding-left: 15px;
    }
}

.pr {
    padding-right: calc(100vw / 2 - 1240px / 2 + 12px);
    @include up-xxxl {
        padding-right: calc(100vw / 2 - 1480px / 2 + 12px);
    }
    @include down-xxl {
        padding-right: 15px;
    }
}

.wpcf7-not-valid,
.error {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    border: 1px solid #f00!important;
}

.wpcf7-not-valid-tip {
    display: none!important;
}

.wpcf7-spinner {
    position: absolute;
    right: 15px;
    margin: 0;
}

.wpcf7-response-output {
    display: none;
}

.screen-reader-response {
    display: none;
}


/* blog background */

.blog-bg-image {
    min-height: 458px;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 60px;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-postion-center {
    background-position: center;
}

.bg-postion-top-center {
    background-position: top center;
}

.bg-postion-right-center {
    background-position: right center;
}

.bg-postion-bottom-center {
    background-position: bottom center;
}

.bg-postion-left-center {
    background-position: left center;
}

.bg-postion-top-right {
    background-position: top right;
}

.bg-postion-bottom-right {
    background-position: bottom right;
}

.bg-postion-left-top {
    background-position: left top;
}

.bg-postion-right-top {
    background-position: right top;
}


/* blog background END */

.fs-20 {
    @include fs-20();
}

.fs-24 {
    @include fs-24();
}

.fs-30 {
    @include fs-30();
}

.fs-32 {
    @include fs-32();
}

.fs-36 {
    @include fs-36();
}

.fs-40 {
    @include fs-40();
}

.fs-60 {
    @include fs-60();
}

.container-over-background {
    box-shadow: 0 0 0 100vmax #121212;
    clip-path: inset(0 -100vmax);
    background-color: #121212;
}

html {
    scroll-padding-top: 200px;
}

// Usage
// class="ui-pt-res-10"
// class="ui-mb-res-10"
// @include ui-res-pb(70px);
// @include fsize(56px);  
// @include fs-56();  
//          sass --watch sass/main.scss:css/main.min.css --style compressed 
// inline-size: 100%;
// aspect-ratio: 9 / 3;
// object-fit: cover;